import React from 'react';
import DataProducts from './DataProducts'
import styled from 'styled-components';
import {
    BrowserRouter as Router,
    Link
  } from "react-router-dom";




const ProductHighlight = () => {
    var product = DataProducts.find(product => product.id === 4)

  return (
    <StyledProductHighlight>
        
       
    <div className="highlight">
        
        <img src={"/productafbeeldingen/"+product.images[0]} alt="product afbeelding" />
        <div className="copy">
            <h1>{product.title}</h1>
            <p>{product.spotlight}</p>
            <div className="links">
                <Link to="/dealers">Dealer zoeken</Link>
                <Link to={`/producten/${product.url}`} >Product details </Link>
            </div>    
        </div>    
    </div>
    </StyledProductHighlight>
  )
}
const StyledProductHighlight = styled.div`
div{
    display: flex;
    justify-content: center;
    margin-top: 10px;
    align-items: center;
    flex-wrap: wrap;
    @media (min-width: 768px) {
      margin-top: 20px;
      flex-wrap: wrap;
    }

    @media (min-width: 1024px) {
      margin-top: 50px;
      flex-wrap: no-wrap;
    }  

}
div img{
    width:90vw;
    @media (min-width: 768px) {
      width:80vwx;
    }

    @media (min-width: 1024px) {
      width:700px;
    }  
}

div div{
    max-width: 500px;
    flex-direction: column;
    margin-left: 40px;
    margin-right: 40px;
    align-items: stretch;
}
div h1{
  border-bottom: 2px solid #FC751B;
  padding-bottom: 6px;
  margin-bottom: 10px;
  display: inline;
  color: white;
  font-size: 24px;
  font-weight: bold;
  @media (min-width: 768px) {
    font-size: 32px;
  }

  @media (min-width: 1024px) {
    font-size: 32px;
  }
}
p{
  color: white;
  font-size: 1.2em;
  margin-bottom: 30px;
  line-height: 24px;
  @media (min-width: 768px) {
    font-size: 1.4em;
  }

  @media (min-width: 1024px) {
    font-size: 1.4em;
    line-height: 1.2em;
  }
}
.links{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 0;
    
    
}

.links a{
    color: white;
    text-decoration: none;
    border: 1px solid white;
    padding: 10px;
    border-radius: 10px;
    display: inline-block;
    margin: 0 20px 20px 0;
    font-size: 1.4em;
  
}
.subtitle{
    font-size: 16px;
    margin: 0 0 30px 0;
}
`
export default ProductHighlight