import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <StyledEvents>
      <div className="hero">
        <div className="heroCopy">
          <div>
            <h1>404</h1>
            <p>De pagina die u zocht bestaat niet (meer). Ga verder naar home.</p>
            <Link to="/">Home</Link>
          </div>
        </div>
      </div>
    </StyledEvents>
  );
};

const StyledEvents = styled.div`
  .hero {
    max-width: 100vw;
    margin-top: 100px;
  }

  h1 {
    padding-bottom: 5px;
    max-width: 400px;
    color: white;
    font-size: 40px;
    font-weight: bold;
    border-bottom: 6px solid #fc751b;
    margin-bottom: 10px;
  }

  p {
    font-size: 25px;
    margin-bottom: 30px;
    color: #fff;
  }

  .heroCopy {
    position: absolute;
    top: 40vh;

    z-index: 10;
    text-align: left;
    width: 100%;
    padding: 10px;

    @media (min-width: 768px) {
      width: 50%;
    }

    @media (min-width: 1024px) {
      width: 30%;
      left: 50%;
      top: 48vh;
    }
  }

  .heroCopy a {
    color: #fff;
    font-weight: bold;
    font-size: 30px;
    text-decoration: none;
    border: 1px solid #fff;
    padding: 8px;
    border-radius: 10px;
  }
  
  .heroCopy a:hover {
    border: 1px solid #fff;
  }
`;

export default PageNotFound;
