import React, { useState } from 'react';
import styled from 'styled-components';

const StyledSelectShow = styled.div`
button{
    padding: 10px;
    background-color: white;
    border: grey 1px solid;
    min-width: 300px;
    margin: -60px 0px 60px 0pc;
    font-size: 30px;
    
   
}
button:focus{
    outline: 0;
}
.active{
    color: black;
    text-decoration: underline;
    border-bottom: none;
    text-decoration-color: #FC751B;
}
.inactive{
    color: grey;
    border: none;
    border-bottom: 1px solid grey;
}
.buttons{
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
} 
`;

const SelectShow = ({ showItems, clickHandlerShowItem }) => {
  
  
  return (
    
      <StyledSelectShow >
            <div className="buttons">
                <button className={showItems === "thermal" ? "active" : "inactive"} onClick={event => {
                    clickHandlerShowItem("thermal");
                }}>Warmtebeeld</button>
                <button className={showItems === "nightvision" ? "active" : "inactive"} onClick={event => {
                    clickHandlerShowItem("nightvision");
                }}>Nachtzicht</button>
                 
               
            </div>
      </StyledSelectShow>
      
    
  )
}

export default SelectShow