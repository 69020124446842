import React, {useState} from "react"
import logo from "./assets/logo-lahoux.png";
import sunrise from "./assets/zonsopgang.jpg";
import Navbar from './components/Nav/Navbar.jsx';
import RightNav from './components/Nav/RightNav.jsx';
import './App.css';
import Home from "./components/Home"
import HomeArticle from "./components/HomeArticle"
import Glossary from "./components/Glossary"
import Footer from "./components/Footer"
import Aboutus from "./components/Aboutus"
import Dealers from "./components/Dealers"
import Service from "./components/Service"
import Contact from "./components/Contact"
import Bird from "./components/Bird"
import Explanations from "./components/Explanation"
import ProductHighlight from "./components/ProductHighlight"
import Productoverview from "./components/Productoverview"
import Productdetail, {productArray}from "./components/Productdetail"
import ProductSpecs from "./components/ProductSpecs";
import Events from "./components/Events"
import EventsAll from "./components/EventsAll"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from "react-helmet";
import PageNotFound from "./components/PageNotFound";
import CookiePolicy from "./components/CookiePolicy";
import HeroCookiePolicy from "./components/HeroCookiePolicy";
import CookieBanner from "./components/CookieBanner";

function ScrollToTop() {
  const history = useHistory()
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    }
  }, []);

  return (null);
}

function App({open}) {
  const [showItems, setShowItems] = useState("thermal")
  const [showCookieBanner, setShowCookieBanner] = useState(true)

  const clickHandlerShowItem = value =>{
    setShowItems(value)
  }

  const clickHandlerCookieBanner = () =>{
    setShowCookieBanner(false)
    localStorage.setItem("cookieMessageLB", "closed")
  }
  const cookieMessageClosed = localStorage.getItem("cookieMessageLB")


  return (
    <div className="App">

      <Helmet titleTemplate="%s | Lahoux Birding - nachtzicht en warmtebeeldkijkers" defaultTitle="Lahoux Birding - nachtzicht en warmtebeeldkijkers">
        <meta name="description" content="De Lahoux Birding warmtebeeldkijkers zijn nuttige instrumenten voor vogelaars en ecologen. Zowel overdag als 's nachts geven de warmtebeeldkijkers een belangrijke meerwaarde bij het bestuderen van vogels. Naast vogels zijn ook zoogdieren en andere warmbloedige dieren goed te detecteren met de Lahoux Birding warmtebeeldkijkers." />
      </Helmet>

      <ScrollToTop />
      <header className="App-header" style={{ 
          backgroundImage: `url(${sunrise})` 
    }}> 
      <Navbar />
      <Switch>
        <Route exact path="/">
            <Home />
       </Route>
        <Route path="/over-ons">
            <Aboutus/>
        </Route> 
        <Route exact path="/producten">
          <ProductHighlight/>
        </Route>
        <Route path="/evenementen">
            <Events />
        </Route>
        <Route path="/service">
            <Service />
        </Route>
        <Route path="/dealers">
            <Bird />
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
        <Route path="/woordenlijst">
          	<Bird />
        </Route>
        <Route exact path="/producten/:url" >
          <Productdetail />
        </Route>
        <Route path="/cookieverklaring">
          <HeroCookiePolicy />
        </Route>
        <Route path="/404">
            <PageNotFound />
          </Route>
          <Route path="*">
            <PageNotFound />
          </Route>
      </Switch>   
      </header>
        <Switch>
        <Route exact path="/">
            <HomeArticle />
        </Route>
        <Route path="/over-ons">
            <Explanations showItems={showItems} clickHandlerShowItem={clickHandlerShowItem}/>
        </Route> 
        <Route exact path="/producten">
          <Productoverview showItems={showItems} clickHandlerShowItem={clickHandlerShowItem}/>
        </Route>
        <Route path="/evenementen">
            <EventsAll />
        </Route>
        <Route path="/service">
            <Dealers />
        </Route>
        <Route path="/dealers">
            <Dealers />
        </Route>
        <Route path="/contact">
          
        </Route>
        <Route path="/woordenlijst">
          <Glossary showItems={showItems} clickHandlerShowItem={clickHandlerShowItem}/>
        </Route>
        <Route exact path="/producten/:url" >
          <ProductSpecs />
        </Route>
        <Route path="/cookieverklaring">
          <CookiePolicy />
        </Route>
        
      </Switch> 

      {cookieMessageClosed ? null :
        <CookieBanner clickHandlerCookieBanner={clickHandlerCookieBanner} />
      }
      
      <Footer />
      
      
    </div>
  );
}

export default App;
