const DataProducts = [
    {
        id: 11,
        type: "thermal",
        title: "Lahoux Spotter NL 325",
        url: "Lahoux-Spotter-NL-325",
        price: "1234.48",
        new: false,
        shortDes: "De Lahoux Spotter NL 325 is een hoogwaardige, compacte warmtebeeldcamera bedoeld voor observatie. Door gebruik te maken van de nieuwste technologie biedt de Lahoux Spotter NL 325 het scherpste beeld en de beste prestaties in zijn klasse.",
        longDes: "De Lahoux Spotter NL 325 is een hoogwaardige, compacte warmtebeeldcamera bedoeld voor observatie. Door gebruik te maken van de nieuwste technologie biedt de Lahoux Spotter NL 325 het scherpste beeld en de beste prestaties in zijn klasse. Niets blijft meer verborgen, ongeacht het weer.",
        longDes_2: "De energiezuinige elektronica en lange batterijduur maken langdurig ononderbroken gebruik mogelijk. Het ongecompliceerde ergonomische ontwerp biedt optimaal comfort en een hoge mate van inzetbaarheid. De Lahoux Spotter NL 325 wordt geheel in Nederland geproduceerd volgens de hoogste kwaliteitsnormen.",
        number: "02-0002-03526",
        flyer: "Lahoux_Birding_Spotter_NL_serie-NL.pdf",
        accessoires: ["Tasje", "Lensdoekje", "Gebruiksaanwijzing", "Draagriem", "18650 batterij"],
        images: ["Lahoux-Spotter-NL-325.png", "Lahoux-Spotter-NL-325-2.png", "Lahoux-Spotter-NL-325-3.png", "Lahoux-Spotter-NL-325-4.png", "Lahoux-Spotter-NL-325-5.png"],
        techs: { 
            Detector: {"Sensorresolutie": "384x288", "Pixel pitch": "12 μm", "Beeldfrequentie": "50 Hz", "Spectraal bereik": "8-14 μm", "Warmtegevoeligheid (NETD)": "<40 mK"},
            Optiek: {"Lens": "25 mm", "Apeture": "F/1.0", "Gezichtveld (FOV)": "10.5° × 7.91°", "Optische vergroting": "2,3x, 4,6x, 9,2x", "Digitale zoom": "1×, 2×, 4x"},
            Elektronica: {"Beeldscherm": "OLED 1024×768", "Video-uitgang (analoog)": "Wifi", "Batterij": "18650 li-ion batterij"},
            Operationeel: {"Afmetingen (l×b×h)": "168×61×50", "Gewicht": "0.41 kg", "Waterdichtheid": "IP67", "Temperatuurbereik": "-20 °C tot 40 °C", "Batterijduur (besparingsmodus)": "6-8 uur (afhankelijk van de gebruiksomstandigheden)"}
        }
    },
    {
        id: 12,
        type: "thermal",
        title: "Lahoux Spotter NL 350",
        url: "Lahoux-Spotter-NL-350",
        price: "1372.41",
        new: false,
        shortDes: "De Lahoux Spotter NL 350 is een hoogwaardige, compacte warmtebeeldcamera bedoeld voor observatie. Door gebruik te maken van de nieuwste technologie biedt de Lahoux Spotter NL 350 het scherpste beeld en de beste prestaties in zijn klasse.",
        longDes: "De Lahoux Spotter NL 350 is een hoogwaardige, compacte warmtebeeldcamera bedoeld voor observatie. Door gebruik te maken van de nieuwste technologie biedt de Lahoux Spotter NL 350 het scherpste beeld en de beste prestaties in zijn klasse. Niets blijft meer verborgen, ongeacht het weer.",
        longDes_2: "De energiezuinige elektronica en lange batterijduur maken langdurig ononderbroken gebruik mogelijk. Het ongecompliceerde ergonomische ontwerp biedt optimaal comfort en een hoge mate van inzetbaarheid. De Lahoux Spotter NL 350 wordt geheel in Nederland geproduceerd volgens de hoogste kwaliteitsnormen",
        number: "02-0002-03527",
        flyer: "Lahoux_Birding_Spotter_NL_serie-NL.pdf",
        accessoires: ["Tasje", "Lensdoekje", "Gebruiksaanwijzing", "Draagriem", "18650 batterij"],
        images: ["Lahoux-Spotter-NL-350.png", "Lahoux-Spotter-NL-350-2.png", "Lahoux-Spotter-NL-350-3.png", "Lahoux-Spotter-NL-350-4.png", "Lahoux-Spotter-NL-350-5.png"],
        techs: { 
            Detector: {"Sensorresolutie": "384x288", "Pixel pitch": "12 μm", "Beeldfrequentie": "50 Hz", "Spectraal bereik": "8-14 μm", "Warmtegevoeligheid (NETD)": "<40 mK"},
            Optiek: {"Lens": "50 mm", "Apeture": "F/1.2", "Gezichtveld (FOV)": "4.8° × 4.0°", "Optische vergroting": "5x, 10x, 20,2x", "Digitale zoom": "1×, 2×, 4x"},
            Elektronica: {"Beeldscherm": "OLED 1024×768", "Video-uitgang (analoog)": "Wifi", "Batterij": "18650 li-ion batterij"},
            Operationeel: {"Afmetingen (l×b×h)": "186×63×55", "Gewicht": "0.46 kg", "Waterdichtheid": "IP67", "Temperatuurbereik": "-20 °C tot 40 °C", "Batterijduur (besparingsmodus)": "6-8 hours (afhankelijk van de gebruiksomstandigheden)"}
        }
    },
    {
        id: 13,
        type: "thermal",
        title: "Lahoux Spotter NL 625",
        url: "Lahoux-Spotter-NL-625",
        price: "2062.07",
        new: false,
        shortDes: "De Lahoux Spotter NL 625 is een hoogwaardige, compacte warmtebeeldcamera bedoeld voor observatie. Door gebruik te maken van de nieuwste technologie biedt de Lahoux Spotter NL 625 het scherpste beeld en de beste prestaties in zijn klasse.",
        longDes: "De Lahoux Spotter NL 625 is een hoogwaardige, compacte warmtebeeldcamera bedoeld voor observatie. Door gebruik te maken van de nieuwste technologie biedt de Lahoux Spotter NL 625 het scherpste beeld en de beste prestaties in zijn klasse. Niets blijft meer verborgen, ongeacht het weer.",
        longDes_2: "De energiezuinige elektronica en lange batterijduur maken langdurig ononderbroken gebruik mogelijk. Het ongecompliceerde ergonomische ontwerp biedt optimaal comfort en een hoge mate van inzetbaarheid. De Lahoux Spotter NL 625 wordt geheel in Nederland geproduceerd volgens de hoogste kwaliteitsnormen",
        number: "02-0002-03528",
        flyer: "Lahoux_Birding_Spotter_NL_serie-NL.pdf",
        accessoires: ["Tasje", "Lensdoekje", "Gebruiksaanwijzing", "Draagriem", "18650 batterij"],
        images: ["Lahoux-Spotter-NL-625.png", "Lahoux-Spotter-NL-625-2.png", "Lahoux-Spotter-NL-625-3.png", "Lahoux-Spotter-NL-625-4.png", "Lahoux-Spotter-NL-625-5.png"],
        techs: { 
            Detector: {"Sensorresolutie": "640×512", "Pixel pitch": "12 μm", "Beeldfrequentie": "50 Hz", "Spectraal bereik": "8-14 μm", "Warmtegevoeligheid (NETD)": "<40 mK"},
            Optiek: {"Lens": "25 mm", "Apeture": "F/1.0", "Gezichtveld (FOV)": "17.5° × 14°", "Optische vergroting": "1,4x, 2,8x, 5,5x", "Digitale zoom": "1×, 2×, 4x"},
            Elektronica: {"Beeldscherm": "OLED 1024×768", "Video-uitgang (analoog)": "Wifi", "Batterij": "18650 li-ion batterij"},
            Operationeel: {"Afmetingen (l×b×h)": "168×61×50", "Gewicht": "0.41 kg", "Waterdichtheid": "IP67", "Temperatuurbereik": "-20 °C tot 40 °C", "Batterijduur (besparingsmodus)": "6-8 hours (afhankelijk van de gebruiksomstandigheden)"}
        }
    },
    {
        id: 14,
        type: "thermal",
        title: "Lahoux Spotter NL 650",
        url: "Lahoux-Spotter-NL-650",
        price: "2337.93",
        new: false,
        shortDes: "De Lahoux Spotter NL 650 is een hoogwaardige, compacte warmtebeeldcamera bedoeld voor observatie. Door gebruik te maken van de nieuwste technologie biedt de Lahoux Spotter NL 650 het scherpste beeld en de beste prestaties in zijn klasse.",
        longDes: "De Lahoux Spotter NL 650 is een hoogwaardige, compacte warmtebeeldcamera bedoeld voor observatie. Door gebruik te maken van de nieuwste technologie biedt de Lahoux Spotter NL 650 het scherpste beeld en de beste prestaties in zijn klasse. Niets blijft meer verborgen, ongeacht het weer.",
        longDes_2: "De energiezuinige elektronica en lange batterijduur maken langdurig ononderbroken gebruik mogelijk. Het ongecompliceerde ergonomische ontwerp biedt optimaal comfort en een hoge mate van inzetbaarheid. De Lahoux Spotter NL 650 wordt geheel in Nederland geproduceerd volgens de hoogste kwaliteitsnormen",
        number: "02-0002-03529",
        flyer: "Lahoux_Birding_Spotter_NL_serie-NL.pdf",
        accessoires: ["Tasje", "Lensdoekje", "Gebruiksaanwijzing", "Draagriem", "18650 batterij"],
        images: ["Lahoux-Spotter-NL-650.png", "Lahoux-Spotter-NL-650-2.png", "Lahoux-Spotter-NL-650-3.png", "Lahoux-Spotter-NL-650-4.png", "Lahoux-Spotter-NL-650-5.png"],
        videos: {"Eagle-Owl-with-Spotter-NL-650.mp4": "Oehoe (Bubo Bubo) vlak na zijn vrijlating, na herstel in de vogelopvang. Gefilmd met de Lahoux Spotter NL 650."},
        techs: { 
            Detector: {"Sensorresolutie": "640×512", "Pixel pitch": "12 μm", "Beeldfrequentie": "50 Hz", "Spectraal bereik": "8-14 μm", "Warmtegevoeligheid (NETD)": "<40 mK"},
            Optiek: {"Lens": "50 mm", "Apeture": "F/1.2", "Gezichtveld (FOV)": "8.8° × 7.0°", "Optische vergroting": "2,8x, 5,5x, 11,0x", "Digitale zoom": "1×, 2×, 4x"},
            Elektronica: {"Beeldscherm": "OLED 1024×768", "Video-uitgang (analoog)": "Wifi", "Batterij": "18650 li-ion batterij"},
            Operationeel: {"Afmetingen (l×b×h)": "186×63×55", "Gewicht": "0.46 kg", "Waterdichtheid": "IP67", "Temperatuurbereik": "-20 °C tot 40 °C", "Batterijduur (besparingsmodus)": "6-8 hours (afhankelijk van de gebruiksomstandigheden)"}
        }
    },

    {
        id: 17,
        type: "thermal",
        title: "Lahoux Spotter S",
        url: "Lahoux-Spotter-S",
        price: "528.00",
        new: false,
        shortDes: "Het nieuwe juweeltje voor de beginnende warmtebeeldgebruiker. De Lahoux Spotter S is ons nieuwe instapmodel met zeer goede beeldkwaliteit, dit model heeft nu ook wifi en foto/video.",
        longDes: "Het nieuwe juweeltje voor de beginnende warmtebeeldgebruiker. De Lahoux Spotter S is ons nieuwe instapmodel met zeer goede beeldkwaliteit, dit model heeft nu ook wifi en foto/video.", 
        longDes_2: "Het ergonomische ontwerp past goed in de meeste handen en door zijn compacte formaat zelfs in een (broek)zak. Met een ultramoderne detector en het beste beeld in zijn klasse, is de Lahoux Spotter S een betrouwbare partner voor voornamelijk bos en korte afstandsgebruik. Het lage gewicht zorgt voor een lange observatietijd zonder vermoeidheid. De stille knoppen, het snelle opstarten en de lange looptijd zorgen ervoor dat beeldinformatie missen geen optie meer is.", 
        number: "02-0006-03145",
        flyer: "Lahoux-Birding-Spotter-S_NL.pdf",
        images: ["Lahoux-Spotter-S.png", "Lahoux-Spotter-S-2.png", "Lahoux-Spotter-S-3.png", "Lahoux-Spotter-S-4.png"],
        techs: { 
            Detector: {"Resolutie": "256×192", "Pixel pitch": "12μm", "Spectraal bereik": "8μm～14μm", "NETD": "≤50mk", "Beeldfrequentie": "50Hz"},
            Optiek: {"Lens": "10mm", "Apeture": "F1.0", "Gezichtveld (FOV)": "17.5°×13.1°", "Vergroting": "1.8x optisch. 2x digitaal"},
            Elektronica: {"Beeldscherm": "1280 x 960 hoge-resolutie FLCOS Display", "Batterij": "18650 verwisselbare batterij", "Video-uitgang": "USB type C interface (video out)", "Wifi": "Wifi-transmissie van video", "Opname": "photo / video"},
            Operationeel: {"Afmetingen": "130x40x70", "Gewicht": "320 gram", "Waterdichtheid": "IP66", "Batterijduur": "8 uur bij 25°C (afhankelijk van de gebruiksomstandigheden), afhankelijk van de gebruikte 18650 batterij"}
        },
    },


	{
		id: 1,
        type: "thermal",
		title: "Lahoux Spotter Mini",
        url: "Lahoux-Spotter-Mini",
		price: "646.26",
        shortDes: "De Lahoux Spotter S, Lahoux Spotter Mini en Pro V2 zijn de 3 basismodellen van Lahoux. Deze  warmtebeeldkijkers zijn goed te gebruiken om vogels en andere dieren op te sporen over kortere afstanden. Deze modellen hebben wifi en foto/ videofunctie.",
        longDes: "Licht, klein en handig – de Lahoux Spotter Mini is de perfecte instap in de fascinerende warmtebeeldtechnologie. Zelfs in absolute duisternis of dicht gebladerte kunt u met deze warmtebeeldcamera zien wat eerder verborgen bleef. De 13 mm-lens biedt een breed gezichtsveld. Met een pixelafstand van 12 μm en een resolutie van 256 x 192 pixels is de Lahoux Spotter Mini de beste instapper voor wildobservatie.",
        number: "02-0006-03161",
        accessoires: ["tasje", "USB-kabel met AC-adapter", "gebruiksaanwijzing", "videokabel", "lensdoekje"],
        flyer: "LahouxBirding_Spotter_Serie_NL.pdf",
        images: ["Lahoux_Spotter_Mini_1.png", "Lahoux_Spotter_Mini_2.png"],
        dri: "DRI-tabel_Birding.png",
        techs: {
            Detector: { "Resolutie": "256 x 192", "Pixelpitch": "12 µm"},
            Optiek: {"Lens": "13 mm", "Vergroting (digitaal)": "1x, 2x", "Aperture": "F/1.0"},
            Elektronica: {"USB": "Voor het opladen, kan worden opgeladen door USB-powerbank", "Display": "720 x 540"},
            Operationeel: {"Temperatuurbereik": "−20°C tot +50°C", "Stroomvoorziening": "Interne lithium-ion-batterij", "Batterijduur" : "20 uur (bij 21°C, afhankelijk van de gebruiksomstandigheden)", "Waterdicht": "IP66", "Afmetingen": "160x62x62 mm", "Gewicht": "0,32 kg" }
        }
	},	

 
    {
        id: 4,
        type: "thermal",
        title: "Lahoux Spotter Pro V2",
        url: "Lahoux-Spotter-Pro-V2",
        price: "952.38",
        spotlight: "De nieuwe Lahoux Spotter Pro V2 is de ideale metgezel voor vogelobservatie.",
        shortDes: "De Lahoux Spotter Pro V2 is een uitstekende keus. Het grootste verschil met de Lahoux Spotter S en Mini is dat over iets grotere afstanden gezocht en geobserveerd kan worden. Ook met dit model kunnen videobeelden en foto’s gemaakt worden. Met behulp van de Lahoux App voor op uw smartphone kunnen de foto’s direct worden doorgestuurd of kunt u zelfs live meekijken met de Lahoux Spotter!",
        longDes: "De Lahoux Spotter Pro V2 is de nieuwe dimensie in de warmtebeeldtechnologie. Hij wordt gekenmerkt door de 19mm lens en een pixelafstand van 12μm. Met deze camera is op middellange en grote afstand een uitstekend zoekmachine, zodat u van dichtbij tot grote afstanden vogels kunt observeren en ontdekken. De beeldsnelheid van 50 Hz zorgt voor een absoluut trillingsvrij beeld en de vijf beeldmodi (Black Hot, White Hot, Red Hot, Iron Hot, Blue Hot) zorgen voor een perfecte weergave. Bovendien is het bij de Lahoux Spotter Pro V2 via de geïntegreerde foto- en videofunctie mogelijk om met een Android- of iOS app, tot een maximale afstand van 15 meter, live mee te kijken of foto’s of video te verzenden.",
        number: "02-0006-03751",
//        accessoires: ["tasje", "USB-kabel met AC-adapter", "gebruiksaanwijzing", "videokabel", "lensdoekje", "draagriem"],
//        flyer: "LahouxBirding_Spotter_Serie_NL.pdf",
        images: ["Lahoux-Spotter-Pro-V2-1.png", "Lahoux-Spotter-Pro-V2-2.png", "Lahoux-Spotter-Pro-V2-3.png"],
//        dri: "DRI-tabel_Birding.png",
//        videos: {"eulen.mp4": "Uilen geobserveerd en opgenomen met de Spotter 35", "Nachtzwaluw-Lahoux-Spotter-35.mp4": "Nachtzwaluw (Caprimulgus europaeus) geobserveerd en opgenomen met de Spotter 35"},
        techs: {
            Detector:     { "Resolutie VOx ongekoeld FPA": "384 x 288", "Pixelafstand": "12 µm", "Spectraalbereik": "8-14 µm", "Gevoeligheid (NETD)": "≤ 40 mK", "Beeldfrequentie": "50 Hz"},
            Optiek:       { "Lens": "19 mm", "Optische Vergroting": "1.4x", "Digitale Vergroting": "1x, 2x, 4x", "Dioptriecorrectie": "-4 to＋2"},
            Interface:    { "Video": "USB-C", "Mechanical": "UNC ¼’’-20 (Tripod mounting)",  "Beeldscherm 0.23’’ OLED": "640 × 400", "Wifi": "Overdracht van real-time video via WIFI binnen 15 meter"},
            Operationeel: {"Temperatuurbereik": "-30 °C to 60 °C", "Voeding": "Enkele, vervangbare 18650-batterij", "Batterijduur" : "≥ 10 uur (bij 21°C) *", "Waterdichtheid": "IP66", "Afmetingen": "150×45×74 mm", "Gewicht": "0,33 kg"},
        }        
    },

    {
        id: 19,
        type: "thermal",
        title: "Lahoux Spotter 25 LRF V2",
        url: "Lahoux-Spotter-25-LRF-V2",
        price: "1372.41",
 
        spotlight: "De nieuwe Lahoux Spotter 25 LRF V2 is de ideale metgezel voor vogelobservatie.",

        shortDes: "De Lahoux Spotter 25 LRF V2 is de ideale metgezel voor de mobiele gebruiker; bijvoorbeeld bij vogelobservatie of wandelen. Door het lichte en compacte ontwerp is de Lahoux Spotter 25 LRF V2 gemakkelijk mee te nemen in uw jaszak en zeer geschikt om met één hand te worden bediend.", 
        longDes:  "De Lahoux Spotter 25 LRF V2 is de ideale metgezel voor de mobiele gebruiker; bijvoorbeeld bij vogelobservatie of wandelen. Door het lichte en compacte ontwerp is de Lahoux Spotter 25 LRF V2 gemakkelijk mee te nemen in uw jaszak en zeer geschikt om met één hand te worden bediend.", 
        number: "02-0006-03770",
        images: ["Lahoux_Spotter_25_LRF_V2.png"],
        techs: { 
            Detector:    {"Detector": "384x288 px", "Pixelgrootte, μm": "12", "NETD, mk": "≤40", "Beeldfrequentie, Hz": "50"},
            Optiek:      {"Objectieflens, mm": "25 F/1.1", "Gezichtsveld": "10.4°×7.9°", "Optische vergroting, x": "1.9x", "Digitale zoom": "1x 2x 4x", "Dioptrie aanpassing": "-4 tot +2"},
            Display:     {"Type": "0.23’’ OLED", "Resolutie": "640x400 "},
            Operationeel:{"600m": "Laserafstandsmeter", "150×75×74mm": "Afmetingen", "360g": "Gewicht, (zonder batterij)", "Verwisselbare 18650 Li-ion": "Voeding", "IP66": "Waterdichtheid", "1000m": "Detectie","√": "Wifi"},
        }
    },

    {
        id: 20,
        type: "thermal",
        title: "Lahoux Spotter 35 V2",
        url: "Lahoux-Spotter-35-V2",
        price: "1510.34",

        spotlight: "De nieuwe Lahoux Spotter 35 V2 is de ideale metgezel voor vogelobservatie.",

        shortDes: "Vogelobservatie van de volgende generatie. De Lahoux Spotter 35 V2 is de ideale metgezel voor bos- en veldtochten, zelfs in totale duisternis.", 
        longDes:  "Vogelobservatie van de volgende generatie. De Lahoux Spotter 35 V2 is de ideale metgezel voor bos- en veldtochten, zelfs in totale duisternis. De beeldsnelheid van 50 Hz zorgt voor een trillingsvrij beeld en met de vijf beeldmodi (White Hot, Black Hot, Red Hot, Iron Hot und Blue Hot) kan het beeld naar wens worden geselecteerd. Dankzij de 35 mm optiek, de pixelafstand van 12μm en een resolutie van 384 x 288 pixels kunt u vogels ontdekken, zelfs over langere afstand.",
        number: "02-0006-03844",
        images: ["Lahoux-Spotter-35-V2-1.png","Lahoux-Spotter-35-V2-2.png","Lahoux-Spotter-35-V2-3.png"],        
        techs: {
            Detector:     {"Detector": "384x288 px", "Pixelafstand": "12 µm", "Spectraalbereik": "8-14 µm", "Gevoeligheid (NETD)": "≤ 40 mK", "Beeldfrequentie": "50 Hz"},
            Optiek:       {"Lens": "35 F/1.0", "Optische Vergroting": "2.6x", "Digitale Vergroting": "1x, 2x, 4x", "Dioptriecorrectie": "-4 to＋2"},
            Interface:    {"Video": "USB-C", "Mechanical": "UNC ¼’’-20 (Tripod mounting)",  "Beeldscherm 0.23’’ OLED": "640 × 400", "Wifi": "Overdracht van real-time video via WIFI binnen 15 meter"},
            Operationeel: {"Temperatuurbereik": "-30 °C to 60 °C", "Voeding": "Enkele, vervangbare 18650-batterij", "Batterijduur" : "≥10 uur (bij 21°C) *", "Waterdichtheid": "IP66", "Afmetingen": "150×49×74 mm", "Gewicht": "0,345 kg"},
        }
    },     

    {
        id: 21,
        type: "thermal",
        title: "Lahoux Spotter 35 LRF V2",
        url: "Lahoux-Spotter-35-LRF-V2",
        price: "1682.76",

        spotlight: "De nieuwe Lahoux Spotter 35 LRF V2 is de ideale metgezel voor vogelobservatie.",

        shortDes: "De Lahoux Spotter 35 LRF V2 is de ideale metgezel voor de mobiele gebruiker; bijvoorbeeld bij vogelobservatie of wandelen. Door het lichte en compacte ontwerp is de Lahoux Spotter 35 LRF V2 gemakkelijk mee te nemen in uw jaszak en zeer geschikt om met één hand te worden bediend.", 
        longDes:  "De Lahoux Spotter 35 LRF V2 is de ideale metgezel voor de mobiele gebruiker; bijvoorbeeld bij vogelobservatie of wandelen. Door het lichte en compacte ontwerp is de Lahoux Spotter 35 LRF V2 gemakkelijk mee te nemen in uw jaszak en zeer geschikt om met één hand te worden bediend. De Lahoux Spotter 35 LRF V2 heeft een hoge beeldkwaliteit en het hoge resolutie scherm zorgt voor een scherp beeld met levendige kleuren. Samen met de hoge verversingssnelheid krijgt u een uitstekend bruikbaar beeld en een goed situationeel bewustzijn. De lens biedt een breed gezichtsveld en stelt u in staat meer objecten waar te nemen. De pixelafstand van de detector levert een uitstekend beeld op en maakt detectie over een grote afstand mogelijk.", 
        number: "02-0006-03771",
        images: ["Lahoux_Spotter_35_LRF_V2.png"],
        techs: { 
            Detector:     {"Detector": "384x288 px", "Pixelgrootte, μm": "12", "NETD, mk": "≤40", "Beeldfrequentie, Hz": "50"},
            Optiek:       {"Objectieflens, mm": "35 F/1.0", "Gezichtsveld": "7.5°x5.6°", "Optische vergroting, x": "2.6x", "Digitale zoom": "1x 2x 4x", "Dioptrie aanpassing": "-4 tot +2"},
            Display:      {"Type": "0.23’’ OLED", "Resolutie": "640x400 "},
            Operationeel: {"600m": "Laserafstandsmeter", "150×77×74mm": "Afmetingen", "400g": "Gewicht, (zonder batterij)", "Verwisselbare 18650 Li-ion": "Voeding", "IP66": "Waterdichtheid", "1400m": "Detectie","√": "Wifi"},
        }
    },  

    {
        id: 22,
        type: "thermal",
        title: "Lahoux Spotter Elite 35 V2",
        url: "Lahoux-Spotter-Elite-35-V2",
        price: "2200.00",

        spotlight: "De nieuwe Lahoux Spotter Elite 35 V2 is de ideale metgezel voor vogelobservatie.",

        shortDes: "Vogelobservatie van de volgende generatie. De Lahoux Spotter Elite 35 V2 is de ideale metgezel voor vogelobservatie of wandelen, zelfs in totale duisternis. Door de 640x480 resolutie heeft u een breed gezichtsveld.", 
        longDes:  "Vogelobservatie van de volgende generatie. De Lahoux Spotter Elite 35 V2 is de ideale metgezel voor vogelobservatie of wandelen, zelfs in totale duisternis. Door de 640x480 resolutie heeft u een breed gezichtsveld. De beeldsnelheid van 50 Hz zorgt voor een trillingsvrij beeld en met de vijf beeldmodi (White Hot, Black Hot, Red Hot, Iron Hot und Blue Hot) kan het beeld naar wens worden geselecteerd. Dankzij de 35 mm optiek, depixelafstand van 12μm en een resolutie van 640x480 pixels kunt u vogels ontdekken, zelfs over langere afstand.", 
        number: "02-0006-02420",
        images: ["Lahoux_Spotter_Elite_35_V2.png"],
        techs: {
            Detector:     {"Resolutie VOx ongekoeld FPA": "640x480px", "Pixelafstand": "12 µm", "Spectraalbereik": "8-14 µm", "Gevoeligheid (NETD)": "≤30 mK @ F1 25℃", "Beeldfrequentie": "50 Hz"},
            Optiek:       {"Lens": "35 F/1.0", "Optische Vergroting": "2x", "Digitale Vergroting": "1-8x", "Dioptriecorrectie": "-4 to＋2"},
            Interface:    {"Video": "USB-C", "Mechanical": "UNC ¼’’-20 (Tripod mounting)",  "Beeldscherm 0.4'' FLCOS": "1280x960", "Wifi": "Overdracht van real-time video via WIFI binnen 15 meter"},
            Operationeel: {"Temperatuurbereik": "-30 °C to 60 °C", "Voeding": "2 ingebouwde 18650 batterijen (6000mAh)", "Batterijduur" : "≥4.5 uur (bij 21°C) *", "Waterdichtheid": "IP66", "Afmetingen": "214×65×71mm", "Gewicht": "0,560 kg"},
        }
    },    

    {
        id: 23,
        type: "thermal",
        title: "Lahoux Spotter Elite 35 LRF V2",
        url: "Lahoux-Spotter-Elite-35-LRF-V2",
        price: "2475.86",
        shortDes: "De Lahoux Spotter Elite 35 LRF is de ideale metgezel voor de mobiele gebruiker; bijvoorbeeld bij vogelobservatie of wandelen. Door het lichte en compacte ontwerp is de Lahoux Spotter Elite 35 LRF gemakkelijk mee te nemen in uw jaszak en zeer geschikt om met één hand te worden bediend. Door de 640x480 resolutie heeft u een breed gezichtsveld.", 
        longDes:  "De Lahoux Spotter Elite 35 LRF is de ideale metgezel voor de mobiele gebruiker; bijvoorbeeld bij vogelobservatie of wandelen. Door het lichte en compacte ontwerp is de Lahoux Spotter Elite 35 LRF gemakkelijk mee te nemen in uw jaszak en zeer geschikt om met één hand te worden bediend. Door de 640x480 resolutie heeft u een breed gezichtsveld. De Lahoux Spotter Elite 35 LRF heeft een hoge beeldkwaliteit en het hoge resolutie scherm zorgt voor een scherp beeld met levendige kleuren. Samen met de hoge verversingssnelheid krijgt u een uitstekend bruikbaar beeld en een goed situationeel bewustzijn. De lens biedt een breed gezichtsveld en stelt u in staat meer objecten waar te nemen. De pixelafstand van de detector levert een uitstekend beeld op en maakt detectie over een grote afstand mogelijk.", 
        number: "02-0006-02852",
        images: ["Lahoux_Spotter_Elite_35_LRF_V2a.png"],
        techs: { 
            Detector:     {"Detector": "640x480 Vox", "Pixelgrootte, μm": "12", "NETD, mk": "≤40", "Beeldfrequentie, Hz": "50"},
            Optics:       {"Objectieflens, mm": "35 f/1.0", "Gezichtsveld": "12.5°x 9.4°", "Optische vergroting, x": "1.7", "Digitale zoom": "1x 2x 4x 8x", "Dioptrie aanpassing": "-4 tot +2"},
            Inteface:     {"Beeldscherm": "640x400 AMOLED"},
            Operationeel: {"Laserafstandsmeter, Bereik m": "600", "Afmetingen, mm":  "153×77×74", "Gewicht, (zonder batterij), g": "≤399 ", "Detectiebereik, m": "1400", "verwisselbaar 18650 accu":"Voeding", "Waterdichtheid": "IP66"},
        }
    },



    {
		id: 7,
        type: "night",
        title: "Lahoux LVS-7",
        url: "Lahoux-LVS-7",
        options: {
                Groen: { 
                    1200: {
                            veel: {"price": "3448.28", "number" : "01-0002-01370"},
                            weinig: { "price": "3793.11", "number": "01-0002-01370"},
                            geen: {"price": "4137.94", "number": "01-0002-01370"}
                            },
                    1400: {
                            veel: {"price": "4137.93", "number" : "01-0002-01372"},
                            weinig: { "price": "4551.72", "number": "01-0002-01372"},
                            geen: {"price": "4965.52", "number": "01-0002-01372"}
                            },  
                    1500: {
                            veel: {"price": "4827.59", "number" : "01-0002-01371"},
                            weinig: { "price": "5310.35", "number": "01-0002-01371"},
                            geen: {"price": "5793.11", "number": "01-0002-01371"}
                            },   
                    },                            
                Onyx: {
                    1200: {
                        veel: {"price": "3724.14", "number" : "01-0002-01373"},
                        weinig: { "price": "4096.55", "number": "01-0002-01373"},
                        geen: {"price": "4468.97", "number": "01-0002-01373"}
                        },
                    1400: {
                        veel: {"price": "4413.79", "number" : "01-0002-01364"},
                        weinig: { "price": "4855.17", "number": "01-0002-01364"},
                        geen: {"price": "5296.55", "number": "01-0002-01364"}
                        },  
                     1500: {
                        veel: {"price": "5310.35", "number" : "01-0002-01363"},
                        weinig: { "price": "5841.38", "number": "01-0002-01363"},
                        geen: {"price": "6372.42", "number": "01-0002-01363"}
                        },   
                      }
            },
		shortDes: "Met de Lahoux LVS-7 kunt u comfortabel en lang observeren op de zelfde manier als een verrekijker. Deze kijker kan met verschillende lenzen worden uitgerust.",
        longDes: "De Lahoux LVS-7 is een lichte binoculaire goggle voor comfortabel observeren met twee ogen. Daarom is de Lahoux LVS-7 onmisbaar voor de nachtelijke vogelaar die alleen met beide ogen kan kijken. Hij is eenvoudig in gebruik en de oculairen zijn qua breedte en dioptrie persoonlijk in te stellen. Standaardlevering is met de 1x lens voor algemeen gebruik op kortere afstand waarmee u een grote beeldhoek heeft. Bij deze lens is een optionele 3x “click-on” lens te gebruiken voor een groter bereik. Wilt u over nog grotere afstanden observeren dan zijn er, in plaats van de 1x lens, een 4x, 6x en 10x vergrotend objectief te kiezen. U kunt dus geheel naar wens uw nachtkijker samenstellen. ",
        accessoires: ["tasje", "2x AA batterijen", "handleiding", "lensdoekje"],
        optional: "lensvergroting",
        flyer: "LahouxBirding_LVS-7.pdf",
        images: ["lvs71.png", "lvs72.png", "lvs73.png"],
        techs: {
            Detector: { "Resolutie VOx uncooled FPA": "240 x 180", "Pixelpitch": "17 µm", "Spectraal bereik": "8-14 µm", "Gevoeligheid (NETD)": "< 50 mK", "Beeldfrequentie": "50 Hz"},
            Optiek: {"Lens": "13 mm", "Vergroting (digitaal)": "1x, 2x", "Oogafstand": "Instelling met draaiknop"},
            Elektronica: {"Video-uitgang": "PAL", "USB": "Voor het opladen, kan worden opgeladen door USB-powerbank", "Beeldoverdracht": "Geen", "Display FLCOS": "720 x 540"},
            Operationeel: {"Temperatuurbereik": "−20°C tot +50°C", "Stroomvoorziening": "Interne lithium-ion-batterij", "Batterijduur" : "15 uur", "Waterdicht": "IP66", "Afmetingen": "160x62x62 mm", "Gewicht": "0,32 kg" }
        },
},
{
    id: 8,
    type: "night",
    title: "Lahoux LVS-14",
    url: "Lahoux-LVS-14",
    options: {
        Groen: { 
            1200: {
                    veel: {"price": "2689.66", "number" : "01-0002-02040"},
                    weinig: { "price": "2958.63", "number": "01-0002-02040"},
                    geen: {"price": "3227.59", "number": "01-0002-02040"}
                    },
            1400: {
                    veel: {"price": "3379.31", "number" : "01-0002-02041"},
                    weinig: { "price": "3717.24", "number": "01-0002-02041"},
                    geen: {"price": "4055.17", "number": "01-0002-02041"}
                    },  
            1500: {
                    veel: {"price": "4068.97", "number" : "01-0002-02042"},
                    weinig: { "price": "4475.87", "number": "01-0002-02042"},
                    geen: {"price": "4882.76", "number": "01-0002-02042"}
                    },   
            1700: {
                    veel: {"price": "4896.55", "number" : "01-0002-02105"},
                    weinig: { "price": "5386.21", "number": "01-0002-02105"},
                    geen: {"price": "5875.86", "number": "01-0002-02105"}
                    },   
            1750: {
                    veel: {"price": "4896.55", "number" : "01-0002-02092"},
                    weinig: { "price": "5386.21", "number": "01-0002-02092"},
                    geen: {"price": "5875.86", "number": "01-0002-02092"}
                    },   
            1800: {
                    veel: {"price": "5172.41", "number" : "01-0002-02498"},
                    weinig: { "price": "5689.65", "number": "01-0002-02498"},
                    geen: {"price": "6206.89", "number": "01-0002-02498"}
                    },   
            1900: {
                    veel: {"price": "5517.24", "number" : "01-0002-02499"},
                    weinig: { "price": "6068.96", "number": "01-0002-02499"},
                    geen: {"price": "6620.69", "number": "01-0002-02499"}
                    },   
            1950: {
                    veel: {"price": "6206.90", "number" : "01-0002-02500"},
                    weinig: { "price": "6827.59", "number": "01-0002-02500"},
                    geen: {"price": "7448.28", "number": "01-0002-02500"}
                    },     
            },                            
        Onyx: {
            1200: {
                    veel: {"price": "2965.52", "number" : "01-0002-02043"},
                    weinig: { "price": "3262.07", "number": "01-0002-02043"},
                    geen: {"price": "3558.62", "number": "01-0002-02043"}
                    },
            1400: {
                    veel: {"price": "3655.17", "number" : "01-0002-02044"},
                    weinig: { "price": "4020.69", "number": "01-0002-02044"},
                    geen: {"price": "4386.20", "number": "01-0002-02044"}
                    },  
            1500: {
                    veel: {"price": "4344.83", "number" : "01-0002-02327"},
                    weinig: { "price": "4779.31", "number": "01-0002-02327"},
                    geen: {"price": "5213.80", "number": "01-0002-02327"}
                    },   
            1700: {
                    veel: {"price": "5172.41", "number" : "01-0002-02108"},
                    weinig: { "price": "5689.65", "number": "01-0002-02108"},
                    geen: {"price": "6206.89", "number": "01-0002-02108"}
                    },   
            1750: {
                    veel: {"price": "5172.41", "number" : "01-0002-02109"},
                    weinig: { "price": "5689.65", "number": "01-0002-02109"},
                    geen: {"price": "6206.89", "number": "01-0002-02109"}
                    },   
            1800: {
                    veel: {"price": "5448.28", "number" : "01-0002-02501"},
                    weinig: { "price": "5993.11", "number": "01-0002-02501"},
                    geen: {"price": "6537.94", "number": "01-0002-02501"}
                    },   
            1900: {
                    veel: {"price": "5793.10", "number" : "01-0002-02502"},
                    weinig: { "price": "6372.41", "number": "01-0002-02502"},
                    geen: {"price": "6951.72", "number": "01-0002-02502"}
                    },   
            1950: {
                    veel: {"price": "6206.90", "number" : "01-0002-02503"},
                    weinig: { "price": "6827.59", "number": "01-0002-02503"},
                    geen: {"price": "7448.28", "number": "01-0002-02503"}
                    },  
            }
    },
    shortDes: "De spotting scope van de nacht! De ideale keuze voor iedereen die compact en licht wil observeren in het donker. Deze kijker kan met verschillende lenzen worden uitgerust.",
    longDes: "De Lahoux LVS-14 is een lichte en compacte monoculaire restlichtkijker voor snelle en ongecompliceerde observatie. Voorzien van de 1x lens past hij in zelfs een kleine jaszak. Met de telelenzen is de LVS-14 vergelijkbaar met een spotting scope. Deze mono wordt standaard geleverd met de 1x lens voor algemeen gebruik op kortere afstand. Daarmee heeft u een grote beeldhoek. Optioneel bij deze 1x lens is er een 3x “click-on” lens te gebruiken voor een groter bereik. Wilt u over nog grotere afstanden observeren dan zijn er, in plaats van de 1x lens, een 4x, 6x en 10x vergrotend objectief te kiezen. U kunt dus geheel naar wens uw nachtkijker samenstellen. ",
    accessoires: ["tasje", "2x AA batterijen", "handleiding", "lensdoekje"],
    optional: "lensvergroting",
    flyer: "LahouxBirding_LVS-14.pdf",
    images: ["lvs141.png", "lvs142.png", "lvs143.png"],
    techs: {
        Optiek: {"Lens": "27mm", "Gezichtsveld": "40°", "Oculair Diameter": "27 mm", "Oog afstand": "27 mm", "Relatieve apertuur"  : "F/1.2", "Focusbereik" : "20mm tot ∞", "Vergroting (Digital)" : "1x", "Dioptriecorrectie" : "-6 tot +2" , "Ingebouwde IR-verlichting" :  "905nm short range", "Licht overbelastingssensor" : "Ja"  },
        Elektronica: {"Batterij": "1x CR-123 of 1x AA", "Batterijduur": "30+/50+ Uur"},
        Operationeel: {"Temperatuurbereik": "−51°C tot 49°C", "Waterdicht": "IP67", "Afmetingen" : "113x65x55 mm", "Gewicht zonder batterijen": "0,324 kg" }
    },
},
/*
{
    id: 9,
    type: "thermal",
    title: "Lahoux Buzzard 320 (set)",
    url: "Lahoux-Buzzard-set",
    price: "2432.00",
    shortDes: "De Lahoux Buzzard is een clip-on met thermisch beeld, ontworpen voor een C1 klasse drone. De toepassingen zijn zeer uiteenlopend: agrarisch gebruik, natuurbehoud, redden van weidevogels voor het maaien, zoeken van wilde dieren in de mais, anti-stroperij, industriële inspectie, beveiliging en bewaking.",
    longDes: "De Lahoux Buzzard heeft een maximaal controlebereik van ongeveer 800 meter. De thermische module heeft een resolutie van 320×256 pixels. Het zoekgebied is 19,4 x 15 meter op 30 meter hoogte. De Lahoux Buzzard heeft een premium lichtgewicht koolstofvezel behuizing, is gemakkelijk te bevestigen aan en te verwijderen van verschillende drones, heeft een on-board videorecorder en het thermisch beeld wordt doorgestuurd naar een LCD monitor op een statief.",
    number: "02-0002-03078",
    accessoires: ["Monitor", "Statief", "Drone", "4x Lahoux warmtekussen", "Tasje Lahoux"],
    flyer: "Lahoux_Birding_Buzzard_NL.pdf",
    images: ["Lahoux_Buzzard_set_1.png", "Lahoux_Buzzard_set_2.png", "Lahoux_Buzzard_set_3.png", "Lahoux_Buzzard_set_4.png"],
    dri: "DRI-warmtebeeld-Buzzard.png",
    footnote_battery: true,
    videos: {"wm15.mp4": "Lahoux Buzzard met drone"},
    techs: {
        Detector: { "Resolutie": "320 x 256", "Pixelpitch": "12 µm"},
        Optiek: {"Relatieve apertuur": "F/1.0", "Gezichtsveld": "23,4° × 19,2°", "Gezichtsveld op 30 m hoogte": "13,3 × 10,4 m", "Detectie-afstand": "Voertuig: 956 m, Persoon: 414 m, Weidevogelnest: 180 m"},
        Elektronica: {"Interne videorecorder": "Ja", "Opname resolutie": "640 x 480"},
        Operationeel: {"Batterijtype": "Oplaadbare 9V LiPo accu", "Batterijlooptijd": "> 1 uur *", "Afmetingen": "138×59×58 mm", "Gewicht": "115 g" }
    }

},
*/
{
    id: 16,
    type: "thermal",
    title: "Lahoux Buzzard 320",
    url: "Lahoux-Buzzard",
    price: "1500.00",
    shortDes: "De Lahoux Buzzard is een clip-on met thermisch beeld, ontworpen voor een C1 klasse drone. De toepassingen zijn zeer uiteenlopend: agrarisch gebruik, natuurbehoud, redden van weidevogels voor het maaien, zoeken van wilde dieren in de mais, anti-stroperij, industriële inspectie, beveiliging en bewaking.",
    longDes: "De Lahoux Buzzard heeft een maximaal controlebereik van ongeveer 800 meter. De thermische module heeft een resolutie van 320×256 pixels. Het zoekgebied is 19,4 x 15 meter op 30 meter hoogte. De Lahoux Buzzard heeft een premium lichtgewicht koolstofvezel behuizing, is gemakkelijk te bevestigen aan en te verwijderen van verschillende drones, heeft een on-board videorecorder en het thermisch beeld wordt doorgestuurd naar een LCD monitor op een statief.",
    important_info: "Belangrijke informatie over de drone",
    important_info_Des_1: "Wanneer u de Lahoux Buzzard als los apparaat koopt, zonder Hubsan Zino drone, dan dient de drone die u gebruikt aan de volgende eigenschappen te voldoen:",
    important_info_list: ["Aan de onderkant dient er een plat ononderbroken vlak te zijn met een minimaal formaat van: 140 mm lengte en 59 mm breedte.", "De drone moet een extra gewicht van 0,120 kg kunnen dragen.", "De drone moet stabiel genoeg zijn om met lichte wind het extra oppervlak van de Lahoux Buzzard aan te kunnen."],
    number: "02-0002-03379",
    accessoires: ["Monitor", "Statief"],
    flyer: "Lahoux_Birding_Buzzard_NL.pdf",
    images: ["Lahoux_Buzzard_1.png", "Lahoux_Buzzard_2.png", "Lahoux_Buzzard_3.png", "Lahoux_Buzzard_4.png", "Lahoux_Buzzard_5.png"],
    dri: "DRI-warmtebeeld-Buzzard.png",
    footnote_battery: true,
    videos: {"DJI-with-Lahoux Buzzard-manual-landing.mp4": "Handmatige landing van een DJI met Lahoux Buzzard"},
    techs: {
        Detector: { "Resolutie": "320 x 256", "Pixelpitch": "12 µm"},
        Optiek: {"Relatieve apertuur": "F/1.0", "Gezichtsveld": "23,4° × 19,2°", "Gezichtsveld op 30 m hoogte": "13,3 × 10,4 m", "Detectie-afstand": "Voertuig: 956 m, Persoon: 414 m, Weidevogelnest: 180 m"},
        Elektronica: {"Interne videorecorder": "Ja", "Opname resolutie": "640 x 480"},
        Operationeel: {"Batterijtype": "Oplaadbare 9V LiPo accu", "Batterijlooptijd": "> 1 uur *", "Afmetingen": "138×59×58 mm", "Gewicht": "115 g" }
    }
},

{
    id: 18,
    type: "thermal",
    title: "Lahoux Buzzard 640",
    url: "Lahoux-Buzzard-640",
    price: "2000.00",
    shortDes: "De Lahoux Buzzard is een clip-on met thermisch beeld, ontworpen voor een C1 klasse drone. De toepassingen zijn zeer uiteenlopend: agrarisch gebruik, natuurbehoud, redden van weidevogels voor het maaien, zoeken van wilde dieren in de mais, anti-stroperij, industriële inspectie, beveiliging en bewaking.",
    longDes: "De Lahoux Buzzard heeft een maximaal controlebereik van ongeveer 800 meter. De thermische module heeft een resolutie van 640×512 pixels. Het zoekgebied is 19,4 x 15 meter op 30 meter hoogte. De Lahoux Buzzard heeft een premium lichtgewicht koolstofvezel behuizing, is gemakkelijk te bevestigen aan en te verwijderen van verschillende drones, heeft een on-board videorecorder en het thermisch beeld wordt doorgestuurd naar een LCD monitor op een statief.",
    important_info: "Belangrijke informatie over de drone",
    important_info_Des_1: "Wanneer u de Lahoux Buzzard als los apparaat koopt, zonder Hubsan Zino drone, dan dient de drone die u gebruikt aan de volgende eigenschappen te voldoen:",
    important_info_list: ["Aan de onderkant dient er een plat ononderbroken vlak te zijn met een minimaal formaat van: 140 mm lengte en 59 mm breedte.", "De drone moet een extra gewicht van 0,120 kg kunnen dragen.", "De drone moet stabiel genoeg zijn om met lichte wind het extra oppervlak van de Lahoux Buzzard aan te kunnen."],
    number: "02-0002-03703",
    accessoires: ["Monitor", "Statief"],
    flyer: "Lahoux_Birding_Buzzard_NL.pdf",
    images: ["Lahoux_Buzzard_1.png", "Lahoux_Buzzard_2.png", "Lahoux_Buzzard_3.png", "Lahoux_Buzzard_4.png", "Lahoux_Buzzard_5.png"],
    dri: "DRI-warmtebeeld-Buzzard.png",
    footnote_battery: true,
    videos: {"DJI-with-Lahoux Buzzard-manual-landing.mp4": "Handmatige landing van een DJI met Lahoux Buzzard"},
    techs: {
        Detector: { "Resolutie": "640x512", "Pixelpitch": "12 µm"},
        Optiek: {"Relatieve apertuur": "F/1.0", "Gezichtsveld": "23,4° × 19,2°", "Gezichtsveld op 30 m hoogte": "13,3 × 10,4 m", "Detectie-afstand": "Voertuig: 956 m, Persoon: 414 m, Weidevogelnest: 180 m"},
        Elektronica: {"Interne videorecorder": "Ja", "Opname resolutie": "640 x 480"},
        Operationeel: {"Batterijtype": "Oplaadbare 9V LiPo accu", "Batterijlooptijd": "> 1 uur *", "Afmetingen": "138×59×58 mm", "Gewicht": "115 g" }
    }
},

{
    id: 10,
    type: "thermal",
    title: "Lahoux Bino Elite 50 LRF",
    url: "Lahoux-Bino-Elite-50",
    price: "3310.34",
    shortDes: "De Lahoux Bino Elite 50 is een thermische observatiecamera met laser afstandsmeter, speciaal ontworpen voor langdurig observatiegebruik en een uitstekend beeld. Met beide ogen open, wordt de oogvermoeidheid aanzienlijk verminderd en het uitstekende beeld goed waargenomen tijdens langere observatie.",
    longDes: "De Lahoux Bino Elite 50 is een thermische observatiecamera met laser afstandsmeter, speciaal ontworpen voor langdurig observatiegebruik en een uitstekend beeld. Met beide ogen open, wordt de oogvermoeidheid aanzienlijk verminderd en het uitstekende beeld goed waargenomen tijdens langere observatie. De prestaties van de zeer gevoelige VOx ongekoelde IR-detector komen tot leven met de hoogwaardige Oled 1024×768 high-definition full colour beeldschermen. Met een stevig en solide gevoel, verstelbare oculairen, een nauwkeurige laserafstandsmeter en meer functies zoals Foto/video-opname, WIFI, GPS en kompasrichting laat de Lahoux Bino Elite 50 niets te wensen over.", 
    number: "02-0006-03146",
    accessoires: ["Lensdoekje", "USB type-C kabel", "Micro USB-kabel", "Micro HDMI kabel", "Schouderriem", "Adapter", "Tas", "2× 18650 batterij", "18650 batterij oplader"],
    flyer: "Lahoux_Birding_Bino_Elite-50-NL.pdf",
    images: ["Bino_Elite_50-2.png"],
    techs: { 
        Detector: {"Resolutie VOx uncooled": "640x480 px", "Pixelpitch": "12 μm", "Gevoeligheid (NETD)": "≤ 50 mk", "Beeldfrequentie": "50 Hz", },
        Optiek: {"Lens": "50 mm/F1.2", "Gezichtsveld": "8.8°×6.6°", "Vergroting (optisch)": "2.8× to 22.4×", "Vergroting (digitaal)": "1× / 8× (Soepele zoom) microSTOP 200 m", "Dioptriecorrectie": "-4~+2"},
        Elektronica: {"Intern geheugen": "16 GB SD-kaart", "Display OLED": "1024×768 px"},
        Operationeel: {"Bereik laserafstandsmeter": "600 m", "Stroomvoorziening": "Verwijderbare 18650 Li-ion batterij x 2", "Batterijduur (at 25 °C)": "circa 6 uur", "Waterdichtheid": "IP66", "Afmetingen": "162 mm x 146 mm x 70 mm", "Gewicht": "880 g"}
    }
},

{
    id: 15,
    orderID: "6",
    type: "night",
    title: "Lahoux LVS-31",
    url: "Lahoux-LVS-31",
    options: {
        Groen: { 
            1200: {
                    veel: {"price": "3448.28", "number" : "01-0002-03531"},
                    weinig: { "price": "3793.11", "number": "01-0002-03531"},
                    geen: {"price": "4137.94", "number": "01-0002-03531"}
                    },
            1400: {
                    veel: {"price": "4137.93", "number" : "01-0002-03532"},
                    weinig: { "price": "4551.72", "number": "01-0002-03532"},
                    geen: {"price": "4965.52", "number": "01-0002-03532"}
                    },  
            1500: {
                    veel: {"price": "4827.59", "number" : "01-0002-03533"},
                    weinig: { "price": "5310.35", "number": "01-0002-03533"},
                    geen: {"price": "5793.11", "number": "01-0002-03533"}
                    },   
            1700: {
                    veel: {"price": "12379.31", "number" : "01-0002-03534"},
                    weinig: { "price": "13617.24", "number": "01-0002-03534"},
                    geen: {"price": "14855.17", "number": "01-0002-03534"}
                    },   
            1750: {
                    veel: {"price": "12379.31", "number" : "01-0002-03535"},
                    weinig: { "price": "13617.24", "number": "01-0002-03535"},
                    geen: {"price": "14855.17", "number": "01-0002-03535"}
                    },   
            1800: {
                    veel: {"price": "13068.97", "number" : "01-0002-03536"},
                    weinig: { "price": "14375.87", "number": "01-0002-03536"},
                    geen: {"price": "15682.76", "number": "01-0002-03536"}
                    },   
            1900: {
                    veel: {"price": "13758.62", "number" : "01-0002-03537"},
                    weinig: { "price": "15134.48", "number": "01-0002-03537"},
                    geen: {"price": "16510.34", "number": "01-0002-03537"}
                    },   
            1950: {
                    veel: {"price": "14448.28", "number" : "01-0002-03538"},
                    weinig: { "price": "15893.11", "number": "01-0002-03538"},
                    geen: {"price": "17337.94", "number": "01-0002-03538"}
                    },     
            },                            
        Onyx: {
            1200: {
                    veel: {"price": "3724.14", "number" : "01-0002-03539"},
                    weinig: { "price": "4096.55", "number": "01-0002-03539"},
                    geen: {"price": "4468.97", "number": "01-0002-03539"}
                    },
            1400: {
                    veel: {"price": "4413.79", "number" : "01-0002-03540"},
                    weinig: { "price": "4855.17", "number": "01-0002-03540"},
                    geen: {"price": "5296.55", "number": "01-0002-03540"}
                    },  
            1500: {
                    veel: {"price": "5103.45", "number" : "01-0002-03541"},
                    weinig: { "price": "5613.80", "number": "01-0002-03541"},
                    geen: {"price": "6124.14", "number": "01-0002-03541"}
                    },   
            1700: {
                    veel: {"price": "12655.17", "number" : "01-0002-03542"},
                    weinig: { "price": "13920.69", "number": "01-0002-03542"},
                    geen: {"price": "15186.20", "number": "01-0002-03542"}
                    },   
            1750: {
                    veel: {"price": "12655.17", "number" : "01-0002-03543"},
                    weinig: { "price": "13920.69", "number": "01-0002-03543"},
                    geen: {"price": "15186.20", "number": "01-0002-03543"}
                    },   
            1800: {
                    veel: {"price": "13344.83", "number" : "01-0002-03544"},
                    weinig: { "price": "14679.31", "number": "01-0002-03544"},
                    geen: {"price": "16013.80", "number": "01-0002-03544"}
                    },   
            1900: {
                    veel: {"price": "14034.48", "number" : "01-0002-03545"},
                    weinig: { "price": "15437.93", "number": "01-0002-03545"},
                    geen: {"price": "16841.38", "number": "01-0002-03545"}
                    },   
            1950: {
                    veel: {"price": "14724.14", "number" : "01-0002-03546"},
                    weinig: { "price": "16196.55", "number": "01-0002-03546"},
                    geen: {"price": "17668.97", "number": "01-0002-03546"}
                    },  
            }
    },
    shortDes: "De nieuwe Lahoux LVS-31 is een dual-tube goggle systeem met ergonomisch design en verbeterde functionaliteit.",
    longDes: "De nieuwe Lahoux LVS-31 is een dual-tube goggle systeem met ergonomisch design en verbeterde functionaliteit. Het bevat functies zoals een speciale Instant-On-IR knop om snel en tijdelijk de IR-verlichting in te schakelen zonder de schakelaar te hoeven bedienen. De connector aan de voorzijde van het toestel wordt gebruikt om het toestel van stroom te voorzien via vrijwel elke stroombron: externe accu, USB powerbank, 12V auto-aansluiting enz.",
    flyer: "Lahoux Birding_LVS-31_NL.pdf",
    images: ["Lahoux_LVS-31.png", "Lahoux_LVS-31-2.png"],
    techs: {
        Optiek: {"Buizen": "Photonis™, 2+, ECHO, ECHO HF, 4G ((alleen overheid)", "Lenzen": "Lenzen geoptimaliseerd voor ECHO HF en 4G", "Objectieflens": "27 mm", "F-getal": "f/1.2", "Gezichtsveld": "40°", },
        Elektronica: {"Stroomvoorziening": "1× AA or 1× CR123 of extern 4 VDC – 15 VDC", "Batterijlooptijd": "Tot 40 uur (afhankelijk van de gebruiksomstandigheden)" },
        Operationeel: {"Optische vergroting": "1x", "Focusbereik": "25 cm tot ∞", "Dioptrie-aanpassing": "-6 tot +4", "Interpupillaire verstelling": "50 tot 90 mm", "Ingebouwde IR-verlichter": "Ja", "Instant-on-IR-knop": "Ja", "Gebruikstemperatuur": "-40 °C tot +50 °C", "Afmetingen, mm": "112 x 105 x 70 mm", "Gewicht, gram": "675 g"}
    },
},

{
    id: 2,
    type: "thermal",
    title: "Lahoux Spotter Pro V (Niet meer leverbaar)",
    url: "Lahoux-Spotter-Pro-V",
    price: "952.38",
    shortDes: "De Spotter Mini en Pro V zijn de basismodellen van Lahoux. Deze twee warmtebeeldkijkers zijn goed te gebruiken om vogels en andere dieren op te sporen over kortere afstanden. Deze modellen hebben geen wifi of foto/ videofunctie. ",
    longDes: "Wildobservatie van de volgende generatie. De Lahoux Spotter Pro V is de ideale metgezel voor observatie in het bos en het veld, zelfs in totale duisternis. De beeldsnelheid van 50 Hz zorgt voor een trillingsvrij beeld en met de vier beeldmodi (witheet, zwartheet, roodheet en pseudokleuren) kan het beeld naar wens worden geselecteerd. Dankzij de 19 mm-optiek en de pixelafstand van 17 μm en een resolutie van 384 x 288 pixels kunt u wild ontdekken, zelfs over langere afstand.",
    number: "02-0006-03160",
    accessoires: ["tasje", "USB-kabel met AC-adapter", "gebruiksaanwijzing", "videokabel", "lensdoekje"],
    flyer: "LahouxBirding_Spotter_Serie_NL.pdf",
    images: ["prov1.png"],
    dri: "DRI-tabel_Birding.png",
    techs: {
        Detector: { "Resolutie VOx uncooled FPA": "384 x 288", "Pixelpitch": "17 µm", "Spectraal bereik": "8-14 µm", "Gevoeligheid (NETD)": "< 50 mK", "Beeldfrequentie": "50 Hz"},
        Optiek: {"Lens": "19 mm", "Vergroting (digitaal)": "1x, 2x, 4x", "Oogafstand": "Instelling met draaiknop"},
        Elektronica: {"Video-uitgang": "PAL", "USB": "Voor het opladen, kan worden opgeladen door USB-powerbank", "Beeldoverdracht": "Geen", "Display FLCOS": "720 x 540"},
        Operationeel: {"Temperatuurbereik": "−20°C tot +50°C", "Stroomvoorziening": "Interne lithium-ion-batterij", "Batterijduur": "15 uur", "Waterdicht": "IP66", "Afmetingen": "170x65x65 mm", "Gewicht": "0,40 kg" }
    }

},

{
    id: 3,
    type: "thermal",
    title: "Lahoux Spotter 25 (Niet meer leverbaar)",
    url: "Lahoux-Spotter-25",
    price: "1530.61",
    shortDes: "De Lahoux Spotter 25 is de beste keus voor gebruikers die de laatste stand der techniek willen en tevens een grotere beeldhoek zodat u met een mooi en rustig beeld kunt observeren. Door de pixelafstand van 12 µm kunnen videobeelden en foto’s van hoge kwaliteit gemaakt worden. Met behulp van de Lahoux App voor op uw smartphone kunnen de foto’s direct worden doorgestuurd of kunt u zelfs live meekijken met de Lahoux Spotter! ",
    longDes: "De Lahoux Spotter 25 is de alleskunner in ons gamma van warmtebeeldtechnologie. Door de 25mm lens en een pixelafstand van 12μm is de Lahoux Spotter 25 goed inzetbaar op korte en middellange afstanden zodat u zowel dichtbij als op grotere afstand vogels kunt observeren. De beeldsnelheid van 50 Hz zorgt voor een absoluut trillingsvrij beeld en de vier beeldmodi (witheet, zwartheet, roodheet en pseudokleuren) zorgen voor een perfecte weergave. Bovendien heeft de Lahoux Spotter 25 een geïntegreerde foto- en videofunctie zodat u via een Android- of iOS app, tot een maximale afstand van 50 meter, live mee kunt kijken en foto’s of video kunt verzenden.",
    number: "02-0006-02643",
    accessoires: ["tasje", "USB-kabel met AC-adapter", "gebruiksaanwijzing", "videokabel", "lensdoekje", "draagriem"],
    flyer: "LahouxBirding_Spotter_Serie_NL.pdf",
    images: ["251.png", "252.png", "253.png", "254.png"],
    dri: "DRI-tabel_Birding.png",
    techs: {
        Detector: { "Resolutie VOx uncooled FPA": "384 x 288", "Pixelpitch": "12 µm", "Spectraal bereik": "8-14 µm", "Gevoeligheid (NETD)": "< 50 mK", "Beeldfrequentie": "50 Hz"},
        Optiek: {"Lens": "25 mm", "Vergroting (digitaal)": "2x, 4x", "Oogafstand": "Instelling met draaiknop"},
        Elektronica: {"Video-uitgang": "Wifi", "USB": "Voor het opladen, kan worden opgeladen door USB-powerbank", "Beeldoverdracht": "Video / foto-overdracht (8GB intern) via WiFi in combinatie met speciale app", "Display FLCOS": "1280 x 960"},
        Operationeel: {"Temperatuurbereik": "−20°C tot +50°C", "Stroomvoorziening": "Interne lithium-ion-batterij", "Batterijduur": "3,5 uur (wifi ingeschakeld), 7 uur (wifi uitgeschakeld)", "Waterdicht": "IP67", "Afmetingen": "181x65x64 mm", "Gewicht": "0,45 kg" }
    }

},
/*
{
    id: 5,
    type: "thermal",
    title: "Lahoux Spotter 35 (Niet meer leverbaar)",
    url: "Lahoux-Spotter-35",
    price: "1785.71",
    spotlight: "Vogelobservatie van de volgende generatie. De Lahoux Spotter 35 is de ideale metgezel voor bos- en veldzicht, zelfs in totale duisternis.U kunt dit zelfs met de Lahoux App direct met uw mede vogelaars delen. De beelden kunnen op iedere smartphone live meegekeken en bewaard worden. ",
    shortDes: "De Lahoux Spotter 35 is een uitstekende keus. Deze valt tussen de twee basismodellen en de Elite klasse. Het grootste verschil tussen de twee basismodellen en de Lahoux Spotter 35 is dat over grotere afstanden  gezocht en geobserveerd kan worden. Daarnaast kunnen videobeelden en foto’s gemaakt worden. Met behulp van de Lahoux App voor op uw smartphone kunnen de foto’s direct worden doorgestuurd of kunt u zelfs live meekijken met de Lahoux Spotter! ",
    longDes: "De Lahoux Spotter 35 is de nieuwe dimensie in de warmtebeeldtechnologie. Hij wordt gekenmerkt door de grote 35mm lens en een pixelafstand van 12μm. Met deze camera is op middellange en grote afstand een uitstekend zoekmachine, zodat u van dichtbij tot grote afstanden vogels kunt observeren en ontdekken. De beeldsnelheid van 50 Hz zorgt voor een absoluut trillingsvrij beeld en de vier beeldmodi (witheet, zwartheet, roodheet en pseudokleuren) zorgen voor een perfecte weergave. Bovendien is het bij de Lahoux Spotter 35 via de geïntegreerde foto- en videofunctie mogelijk om via een Android- of iOS app, tot een maximale afstand van 50 meter, live mee te kijken of foto’s of video te verzenden.",
    number: "02-0006-02422",
    accessoires: ["tasje", "USB-kabel met AC-adapter", "gebruiksaanwijzing", "videokabel", "lensdoekje", "draagriem"],
    flyer: "LahouxBirding_Spotter_Serie_NL.pdf",
    images: ["351.png", "352.png", "353.png", "354.png"],
    dri: "DRI-tabel_Birding.png",
    videos: {"eulen.mp4": "Uilen geobserveerd en opgenomen met de Spotter 35", "Nachtzwaluw-Lahoux-Spotter-35.mp4": "Nachtzwaluw (Caprimulgus europaeus) geobserveerd en opgenomen met de Spotter 35"},
    techs: {
        Detector: { "Resolutie VOx uncooled FPA": "384 x 288", "Pixelpitch": "12 µm", "Spectraal bereik": "8-14 µm", "Gevoeligheid (NETD)": "< 50 mK", "Beeldfrequentie": "50 Hz"},
        Optiek: {"Lens": "35 mm", "Vergroting (digitaal)": "2x, 4x", "Oogafstand": "Instelling met draaiknop"},
        Elektronica: {"Video-uitgang": "Wifi", "USB": "Voor het opladen, kan worden opgeladen door USB-powerbank", "Beeldoverdracht": "Video / foto-overdracht (8GB intern) via WiFi in combinatie met speciale app", "Display FLCOS": "1280 x 960"},
        Operationeel: {"Temperatuurbereik": "−20°C tot +50°C", "Stroomvoorziening": "Interne lithium-ion-batterij", "Batterijduur": "3,5 uur (wifi ingeschakeld), 7 uur (wifi uitgeschakeld)", "Waterdicht": "IP67", "Afmetingen": "186x65x64 mm", "Gewicht": "0,45 kg" }
    }

},

{
    id: 5,
    type: "thermal",
    title: "Lahoux Spotter Elite 35V (Niet meer leverbaar)",
    url: "Lahoux-Spotter-Elite-35V",
    price: "2295.92",
    spotlight: " Het is de meest geavanceerde warmtebeeldkijker van Lahoux Optics. Het beeld is van uitmuntende kwaliteit waardoor zelfs de kleinste details zichtbaar worden. Hierdoor is het zelfs mogelijk (afhankelijk van afstand en weersomstandigheden) om vogels of zoogdieren te determineren. Met dit Elite model kunnen video’s en foto’s worden gemaakt en kan er via de app voor op uw mobiele telefoon live worden meegekeken. De Lahoux Spotter Elite 35V heeft een van de meest weidse beeldhoeken waardoor deze kijker beter te gebruiken is voor bijvoorbeeld nachttrek tellingen.",
    shortDes: "De twee Lahoux Spotter Elite modellen hebben door de 640x480 pixel detector een grote beeldhoek wat voor een rustig en comfortabel beeld zorgt. Het zijn de meest geavanceerde warmtebeeldkijkers van Lahoux Optics. Het beeld van de kijkers is van uitmuntende kwaliteit waardoor zelfs de kleinste details zichtbaar worden. Hierdoor is het zelfs mogelijk (afhankelijk van afstand en weersomstandigheden) om vogels of zoogdieren te determineren. Ook met de twee Elite modellen kunnen video’s en foto’s worden gemaakt en kan er via de app voor op uw mobiele telefoon live worden meegekeken. De Lahoux Spotter Elite 35V heeft een van de meest weidse beeldhoeken waardoor deze kijker beter te gebruiken is voor bijvoorbeeld nachttrek tellingen.",
    longDes: "De Lahoux Spotter Elite 35V is de nieuwe dimensie van warmtebeeldtechnologie. Het belangrijkste punt van de Elite-serie is de grote detector met een resolutie van 640 × 480 pixels met een pixelafstand van 12μm. Hiermee kun je wild op grote afstanden ontdekken, bepalen en erop reageren. De beeldsnelheid van 50 Hz zorgt voor een absoluut trillingsvrij beeld en de vier beeldmodi (witheet, zwartheet, roodheet en pseudokleuren) zorgen voor een perfecte weergave. Daarbij kan de Lahoux Spotter Elite 35V via de geïntegreerde WiFi-functie via een Android- of iOS app tot een afstand van ongeveer 50 meter live meekijken.",
    number: "02-0006-02414",
    accessoires: ["tasje", "USB-kabel met AC-adapter", "gebruiksaanwijzing", "videokabel", "lensdoekje", "draagriem"],
    flyer: "LahouxBirding_Spotter_Serie_NL.pdf",
    images: ["35v1.png", "35v2.png", "35v3.png", "35v4.png"],
    dri: "DRI-tabel_Birding.png",
    techs: { 
        Detector: { "Resolutie VOx uncooled FPA": "640 × 480", "Pixelpitch": "12 µm", "Spectraal bereik": "8-14 µm", "Gevoeligheid (NETD)": "< 50 mK", "Beeldfrequentie": "50 Hz"},
        Optiek: {"Lens": "35 mm", "Vergroting (digitaal)": "2×, 4×", "Oogafstand": "Instelling met draaiknop"},
        Elektronica: {"Video-uitgang": "wifi", "USB": "Voor het opladen, kan worden opgeladen door USB-powerbank", "Beeldoverdracht": "Video / foto-overdracht (8GB intern) via WiFi in combinatie met speciale app", "Display FLCOS": "1280 × 960"},
        Operationeel: {"Temperatuurbereik": "−20°C tot +50°C", "Stroomvoorziening": "Interne lithium-ion-batterij", "Batterijduur": "3,5 uur (wifi ingeschakeld), 6 uur (wifi uitgeschakeld)", "Waterdicht": "IP67", "Afmetingen": "188×65×64 mm", "Gewicht": "0,45 kg" }
    }

},
*/

{
    id: 6,
    type: "thermal",
    title: "Lahoux Spotter Elite 50V (Niet meer leverbaar)",
    url: "Lahoux-Spotter-Elite-50V",
    price: "2337.93",
    shortDes: "De twee Lahoux Spotter Elite modellen hebben door de 640x512 pixel detector een grote beeldhoek wat voor een rustig en comfortabel beeld zorgt. Het zijn de meest geavanceerde warmtebeeldkijkers van Lahoux Optics. Het beeld van de kijkers is van uitmuntende kwaliteit waardoor zelfs de kleinste details zichtbaar worden. Hierdoor is het zelfs  mogelijk (afhankelijk van afstand en weersomstandigheden) om vogels of zoogdieren te determineren. Ook met de twee Elite modellen kunnen video’s en foto’s worden gemaakt en kan er via de app voor op uw mobiele telefoon live worden meegekeken. De Lahoux Spotter Elite 50V heeft een hogere vergroting waardoor er meer details zichtbaar worden van vogels en dieren op afstand.",
    longDes: "Maximale prestaties, meest innovatieve technologie, maximaal comfort: zijn de belangrijkste eigenschappen van de Lahoux Spotter Elite 50V. Met zijn 50mm lens, de 640 × 480 pixels detector en een pixelgrootte van 12μm mist u zelfs op de grootste afstand geen wild. Via de geïntegreerde wifi-functie kunt u via een Android- of IOS app, tot een maximale afstand van 50 meter, live meekijken of de foto’s of video verzenden. De beeldsnelheid van 50Hz zorgt voor een absoluut trillingsvrij beeld en de vier beeldmodi (witheet, zwartheet, roodheet en pseudokleuren) zorgen voor een perfecte weergave.",
    number: "02-0006-02413",
    accessoires: ["tasje", "USB-kabel met AC-adapter", "gebruiksaanwijzing", "videokabel", "lensdoekje", "draagriem"],
    flyer: "LahouxBirding_Spotter_Serie_NL.pdf",
    images: ["50v1.png", "50v2.png", "50v3.png", "50v4.png"],
    dri: "DRI-tabel_Birding.png",
    techs: { 
        Detector: { "Resolutie VOx uncooled FPA": "640 × 480", "Pixelpitch": "12 µm", "Spectraal bereik": "8-14 µm", "Gevoeligheid (NETD)": "< 50 mK", "Beeldfrequentie": "50 Hz"},
        Optiek: {"Lens": "50 mm", "Vergroting (digitaal)": "2×, 4×", "Oogafstand": "Instelling met draaiknop"},
        Elektronica: {"Video-uitgang": "wifi", "USB": "Voor het opladen, kan worden opgeladen door USB-powerbank", "Beeldoverdracht": "Video / foto-overdracht (8GB intern) via WiFi in combinatie met speciale app", "Display FLCOS": "1280 × 960"},
        Operationeel: {"Temperatuurbereik": "−20°C tot +50°C", "Stroomvoorziening": "Interne lithium-ion-batterij", "Batterijduur": "3,5 uur (wifi ingeschakeld), 6,5 uur (wifi uitgeschakeld)", "Waterdicht": "IP67", "Afmetingen": "202×65×64 mm", "Gewicht": "0,52 kg" }
    }
},

];
export default DataProducts;