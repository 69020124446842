import React from "react";
import styled from "styled-components";

/** Usage of parameters for VideoElement
 *
 * @param video: path to video file (video src);
 * 
 * @param videoRoundClass: video-round - use this to add the class video-round to the VideoElement to display a rounded video;
 * 
 * Examples:
 * <VideoElement video={thermalVideo} videoRoundClass={"video-round"} />
 * <VideoElement video={"/productafbeeldingen/"+item} />
 * 
*/

const VideoElement = ({ video, videoRoundClass }) => {
  
  return (
    <StyledVideo className="video-element">
      <div className={videoRoundClass}>
        <video loop={true} autoPlay={true} muted>
          <source src={video} type="video/mp4" />
        </video>
      </div>
    </StyledVideo>
  );
};

const StyledVideo = styled.div`
  .video-round {
    width: 250px;
    height: 250px;
    border-radius: 250px;
    -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
    position: relative;
    min-width: 250px;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
  }
`;

export default VideoElement;
