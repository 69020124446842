import React from 'react';
import styled from 'styled-components';
import { Helmet } from "react-helmet"
import DataEvents from "./DataEvents";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { faEuroSign } from '@fortawesome/free-solid-svg-icons'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'



const StyledEventsAll = styled.div`
    max-width: 50vw;
    margin: 120px auto 0 auto;
    font-size: 20px;
    h1 {
        font-size: 23px;
        font-weight: bold;
        color: #170E0E9;
        border-bottom: 2px solid #FC751B;
        display: inline-block;
        padding-bottom: 6px;
        
        margin-bottom: 20px;
        
    }
    img{
        width: 18vw;
        height: auto;
    }
    .cards ul{
        display: flex;
        flex-direction: column;
    }
    .cards ul a{
        background-color: white;
        border: 1px solid rgba(112, 112, 112, 0.6);
        margin-bottom: 20px;
        height: 250px;
        text-decoration: none;
        color: #170E0E
    }
    .cards ul a: active, .cards ul a: visitid{
        color: #170E0E;
    }

    .cards ul a li{
        display: flex;
        align-items: middle;
        background-color: white;
        align-items: center;
        overflow: hidden;
    }

    .cards ul a li div{
        border-left: 1px solid #FC751B;
        padding: 0 0 0 20px;
        height: 230px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        width: 29vw;
        margin-top: 10px;
    }
    .cards .button{
        border: 1px solid #FC751B;
        padding: 5px;
        border-radius: 5px;
    }
    .cards h2{
        font-size: 1.2rem;
        color:#FC751B;
        padding-bottom: 5px;
        font-weight: bold;
    }
    .cards p{
        line-height: 20px;
        color: rgba(112, 112, 112, 1)
    }
    .cards span:last-of-type p{
        line-height: 25px;
    }
    b{
        text-decoration: underline; 
        font-weight: bold; 
    }
    svg{
        color: #FC751B;
        margin-right: 5px;
        
    }
    .cards{
        margin-bottom: 60px;
    }

    `

const EventsAll = () => {
  return (
    <StyledEventsAll>
        <Helmet>
          <title>Evenementen</title>
          <meta name="description" content="Regelmatig bieden onze partners excursies aan waarbij u kennis kunt maken met de wereld van warmtebeeldkijkers." />
        </Helmet>

        <span>
        <h1>Evenementen </h1>
        </span>
    <div className="cards">
       <ul>
           <li>Momenteel zijn er geen evenementen gepland.</li>
        {/*}
        	{DataEvents.map(event => (
                <a href={event.url} key={event.id}>   
                    <li>
                        <img src={"/evenement/"+event.logo} alt="event logo" />
                        <div>
                            <span>
                                <h2>{event.title}</h2>
                                <p>{event.desc}</p>
                            </span>
                            <span>
                                <p><FontAwesomeIcon icon={faMapMarkerAlt} /> {event.adress} </p> 
                                <p><FontAwesomeIcon icon={faEuroSign} /> {event.price} </p>
                                <p> <FontAwesomeIcon icon={faCalendarAlt} />{event.datum}</p>
                            </span>
                            <p className="button">Meer informatie</p>
                        </div>                       
                    </li>
                </a>
        
   

  
            ))}*/}
            
       </ul>        
           
    </div>
    </StyledEventsAll>
  )
}

export default EventsAll