import React from 'react';
import icoon from "./../assets/icoonwit.svg"
import styled from 'styled-components';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";



const Service = () => {
  return (
    <StyledService>
        <div>
            <img src={icoon} alt="logo Lahoux Birding" />
            <div>
                <h1>Servicebeleid</h1>
                <p>Bij normaal gebruik is er gedurende vele jaren geen onderhoud nodig aan uw kijker.
                Bent u een particulier en heeft u een probleem met uw kijker, neem dan contact op met uw dealer. 
                <br/>
                <br/>Bent u een dealer en heeft u een kijker die niet goed functioneert?
                Neem dan <Link to="/contact">contact</Link> op met de service afdeling van Lahoux Optics. 
                </p>
            </div>
        </div>        
      
    </StyledService>
  )
}


const StyledService = styled.div`
div{
  display: flex;
  justify-content: center;
  margin-top: 50px;
  align-items: center;
  flex-wrap: wrap;
  @media (min-width: 768px) {
    flex-wrap: wrap;
    margin-top: 50px;
    
  }

  @media (min-width: 830px) {
    flex-wrap: nowrap;
    margin-top: 85px;
  }
}
div img{
  width:70%;

  @media (min-width: 768px) {
    width:50%;
    
  }

  @media (min-width: 1024px) {
    width:500px;
  }
}

div div{
max-width: 500px;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
  align-items: stretch;
  @media (min-width: 768px) {
    margin-left: 10px;
    margin-right: 10px;
  }

  @media (min-width: 1024px) {
    margin-left: 40px;
    margin-right: 0px;
  }
}
  div h1{
    border-bottom: 4px solid #FC751B;
    padding-bottom: 6px;
    margin-bottom: 10px;
    display: inline;
    color: white;
    font-size: 32px;
    font-weight: bold;
}
p, a{
    color: white;
    font-size: 22.4px;
    margin-bottom: 40px;
    line-height: 26px;
}
    
`

export default Service