import React, {useState} from "react"
import DataProducts from "./DataProducts"
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components';
import lens from "./../assets/lens4x.png";
import VideoElement from "./VideoElement"


export const productArray = url => {
    return DataProducts.find(item => {
        return item.url === url;
    })
}

function ProductSpecs() {
    const { url } = useParams()
    const data = productArray(url)
    
    const [fom, setFom] = useState("1200")
    const handleFom = event => setFom(event.target.value)

    const [color, setColor] = useState("Groen")
    const handleColor = event => setColor(event.target.value)

    const [spots, setSpots] = useState("veel")
    const handleSpots = event => setSpots(event.target.value)

    
    return (
        <StyledSpecs>
            {data === undefined ? null :
            <div className="productspec-container">
                <div className="productInfo">
                    <h1> {data.title}</h1>
                    <div className="subtext">
                        <p>{data.subtitle}</p>
                        {data.type === "thermal" &&
                            <div>
                                <p>€ {Math.round(((data.price) * 1.21))} (inclusief 21% BTW Nederland)</p>
                                <p>Artikelnummer: {data.number}</p>
                                <br />
                            </div>
                        }
                        <div className="longtext">
                            <p>{data.longDes}</p>
                            <p>{data.longDes_2}</p>

                            { data.accessoires &&
                                <p>Wordt standaard geleverd met de volgende accessoires: {data.accessoires.join(", ")}. </p>
                            }
                            <br />

                            { data.important_info &&
                            <div>
                                <h2>{data.important_info}</h2>
                                <p>{data.important_info_Des_1}</p>
                                <ul>
                                    {data.important_info_list.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            </div>
                        }
                        </div>

                        {data.type === "night" &&
                            <div>
                                <p className="explain-nv-price">De prijs verschilt per kleur/FOM/spots combinatie. Kiest u de combinatie die het beste bij u past.</p>
                                <p>Nachtzicht is beschikbaar in groen of zwart/wit (Onyx).</p>
                                <ul className="optionsNight">
                                <label className={color === "Groen" ? "active" : ""}>Groen
                                        <input type="radio" onChange={handleColor} checked={color==="Groen"} value="Groen"/>
                                        </label>
                                        <label className={color === "Onyx" ? "active" : ""}>Onyx
                                        <input type="radio" onChange={handleColor} checked={color==="Onyx"} value="Onyx"/>
                                        </label>
                                </ul>
                                <p>De {data.title} is leverbaar met verschillende FOM waardes.</p>
                                <ul className="optionsNight">
                                    {data.url === "Lahoux-LVS-7" ? <div>
                                        <label className={fom === "1200" ? "active" : ""}>Minimum: 1200
                                            <input type="radio" onChange={handleFom} checked={fom==="1200"} value="1200"/>
                                        </label>
                                        <label className={fom === "1400" ? "active" : ""}>Minimum: 1400
                                            <input type="radio" onChange={handleFom} checked={fom==="1400"} value="1400"/>
                                        </label>
                                        <label className={fom === "1500" ? "active" : ""}>Minimum: 1500
                                        <   input type="radio" onChange={handleFom} checked={fom==="1500"}  value="1500"/>
                                        </label>
                                       
                                    </div>
                                        :
                                        <div>
                                            <label className={fom === "1200" ? "active" : ""}>Minimum: 1200
                                                <input type="radio"  onChange={handleFom} checked={fom==="1200"} value="1200"/>
                                            </label>
                                            <label className={fom === "1400" ? "active" : ""}>Minimum: 1400
                                                <input type="radio" onChange={handleFom} checked={fom==="1400"} value="1400"/>
                                            </label>
                                            <label className={fom === "1500" ? "active" : ""}>Minimum: 1500
                                                <input type="radio" onChange={handleFom} checked={fom==="1500"}  value="1500"/>
                                            </label>
                                            <label className={fom === "1700" ? "active" : ""}>Minimum: 1700
                                                <input type="radio" onChange={handleFom} checked={fom==="1700"}  value="1700"/>
                                            </label>
                                            <label className={fom === "1750" ? "active" : ""}>Minimum: 1750
                                                <input type="radio" onChange={handleFom} checked={fom==="1750"}  value="1750"/>
                                            </label>
                                            <label className={fom === "1800" ? "active" : ""}>Minimum: 1800
                                                <input type="radio" onChange={handleFom} checked={fom==="1800"}  value="1800"/>
                                            </label>
                                            <label className={fom === "1900" ? "active" : ""}>Minimum: 1900
                                                <input type="radio" onChange={handleFom} checked={fom==="1900"}  value="1900"/>
                                            </label>
                                            <label className={fom === "1950" ? "active" : ""}>Minimum: 1950
                                                <input type="radio" onChange={handleFom} checked={fom==="1950"}  value="1950"/>
                                            </label>
                                        </div>}

                                </ul>
                                <p>Selecteer de spot categorie.</p>
                                <ul className="optionsNight">
                                    <label className={spots === "veel" ? "active" : ""}>Toegestaan
                                        <input type="radio" onChange={handleSpots} checked={spots==="veel"} value="veel"/>
                                    </label>
                                    <label className={spots === "weinig" ? "active" : ""}>Enkele
                                        <input type="radio" onChange={handleSpots} checked={spots==="weinig"} value="weinig"/>
                                    </label>
                                    <label className={spots === "geen" ? "active" : ""}>Bijna geen
                                        <input type="radio" onChange={handleSpots} checked={spots==="geen"}  value="geen"/>
                                    </label>
                                </ul>
                                <div>
                                    <p className="price">€ {Math.round((Number(data.options[color][fom][spots].price)*1.21))} <span>(inclusief 21% BTW Nederland)</span></p>
                                    <p>Artikelnummer: {data.options[color][fom][spots].number}</p>
                                    
                                </div>
                            </div>
                        }
                    </div>

                </div>

                {data.videos && 

                    <div className="videos">
                        {Object.keys(data.videos).map(video => (
                            <>
                                <VideoElement video={"/productafbeeldingen/"+video} />
                                <p className="caption">{data.videos[video]}</p>
                            </>
                        ))}
                    </div>
                }

                <div className="techs">
                    <h2>Technische specificaties</h2>
                    {data.flyer && 
                        <a href={"/productflyers/"+ data.flyer} download > <FontAwesomeIcon icon={faDownload} />Download productflyer PDF</a>
                    }

                </div>
                <div className="specs">

                    <div>

                        <div>{Object.keys(data.techs).map((key) => <div key={key}><h3>{key}</h3><ul>{Object.keys(data.techs[key]).map(spec => (
                            <li key={spec}><p>{data.techs[key][spec]}</p><p>{spec}</p></li>
                        ))}</ul></div>)}</div>


                    </div>

                    { data.footnote_battery &&
                            <p className="footnote"><span>* </span>Afhankelijk van de gebruiksomstandigheden</p>
                        }

                </div>

                {data.dri &&
                    <div className="dri">
                        <h2>DRI waarden</h2>
                        <img src={"/dri/" + data.dri} alt="dri waarden per uitvoering warmtebeeldkijker" className="driImage" loading="lazy" />
                    </div>
                }

                {(data.type === "night" && (data.url === "lvs-7" || data.url === "lvs-14")) &&
                    <div className="optional">
                        <h2>Optionele accessoire</h2>
                        <p>Om uw {data.title} compleet te maken kunt u de kijker aanvullen met een Lahoux vergrotingslens.</p>
                        <p>Wij raden onze vogelaars een vergrotingslens aan die 4x vergroot. </p>
                        <p>Deze lens is een vaste vergrotingslens, geschikt voor Lahoux LVS-7 en Lahoux LVS-14.</p>
                        <h3>Lahoux Fixed Lens 4x</h3>
                        <p className="price">€ 327,00 <span>(inclusief 21% BTW Nederland)</span></p>
                        <p>Techniek: 100mm f/1.5</p>
                        <img src={lens} className="lens" alt=" Lahoux vergrotingslens 4x" />

                    </div>

                }

            </div>
            }
        </StyledSpecs>
    )
}
const StyledSpecs = styled.div`
 max-width: 100vw;

 .productspec-container { 
    padding: 20px;
 @media (min-width: 768px) {
    max-width: 80vw;
    
  }

  @media (min-width: 1400px) {
    max-width: 40vw;
  }
 margin: 100px auto 0 auto;
}

    h1, h2  {
        border-bottom: 2px solid #FC751B;
        display: inline-block;
        padding-bottom: 6px;
        color: #170E0E;
        font-size: 28px;
        font-weight: bold;
        
    }
    h1{
        margin-bottom: 5px;
    }
    h2{
        margin-bottom: 20px;
    }
    p{
        font-size: 20px;
        line-height: 22px;
    }
    .subtext{
        font-size: 14px;
        color: #988F8F;
        line-height: 18px;
        margin-bottom: 15px;
    }
    .longtext{
        color: #988F8F;
        line-height: 18px;
        margin-bottom: 20px;
    }

    .longtext ul {
        padding-left: 22px;
    }
    .longtext li{
        line-height: 22px;
        font-size: 20px;
        padding-bottom: 2px;
    }
    .longtext li:before{
        content: "- ";
        display: inline-block;
        width: 1em;
        margin-left: -1em;
    }
    .longtext div{
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .longtext p {
        margin-bottom: 15px;
    }
    .techs{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        color: #988F8F;
    }
    a:link, a:visited, a:hover, a:active{
        color: #746868;
        text-decoration: none;
        color: #170E0E;
    text-decoration: none;
    border: 1px solid #FC751B;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 50px;
    display: inline-block;
   }

   a svg{
    color: #FC751B;
    margin-right: 5px;
    
    }
    .specs{
        margin-bottom: 20px;
    }
    .specs li{
        list-style: none;
        width: 80vw;
        margin-bottom: 10px;
        @media (min-width: 768px) {
            min-width: 30vw;
          }
        
          @media (min-width: 1024px) {
            width: 13vw;
          }
    }
    .specs h3{
        color: #FC751B;
        border-bottom: 1px solid #C1B8B8;
        font-size: 24px;
        margin-bottom: 5px;
    }
    .specs p{
        color: #C1B8B8;
    }
    .specs li p:first-of-type{
        font-size: 20px;
    }
    .specs li p:last-of-type{
        font-size: 14px;
    }
    .specs ul{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
        @media (min-width: 768px) {
            flex-wrap: wrap;
          }
        
          @media (min-width: 1024px) {
            flex-wrap: wrap;
          }
    }
    .explain-nv-price {
        margin-bottom: 20px;
    }
    .optionsNight label{
        padding: 10px;
        font-size: 20px;
        border: 1px solid #FC751B;
        display: block;
        border-radius: 10px;
        margin-top: 10px;
        margin-right: 15px;
        text-align: center;
        
    }
    .optionsNight .active{
        border: 4px solid #FC751B;
    }
    .optionsNight label> input:checked{
        border: 4px solid #FC751B;
    }


    .optionsNight li:first-of-type{
        border: 4px solid #FC751B;
    }
    .optionsNight, .optionsNight div{
        display: flex;
        flex-wrap: wrap;
    }
    .optionsNight{
        margin-bottom: 40px;
    }

    .optionsNight input{
        visibility: hidden;
    }

    .optional{
        overflow: hidden;
        color: #988F8F;
    }
    .lens{
        
        max-width: 100%;
    }
    .price{
        color: #FC751B;
        font-weight: bold;
        margin-bottom: 10px;
    }
    .price span{
        color: #988F8F;
        font-size: 14px;
        font-weight: normal;
    }
    .optional h3{
        font-weight: bold;
        margin-top: 20px;
        border-bottom: 2px solid #FC751B;
        margin-bottom: 5px;
        display: inline-block;
        padding-bottom: 6px;
        color: #170E0E;
    }
    .dri{
        margin-bottom: 80px;
    }
    .driImage{
        max-width: 100%;
        display: block;
    }
    .FXoWx .sliderimg{
        height: 100%!important;
    }

    .footnote {
        font-size: 16px;
    }

    .video-element {
        margin-bottom: 40px;
    }

    .videos .video-element, .photography img {
    margin-bottom: 6px;

    @media (min-width: 720px) {
      max-width: 80%;
    }
  }

  .videos .caption, .photography .caption {
    font-size: 16px;
    font-style: italic;
    margin-bottom: 40px;
  }

    `
export default ProductSpecs