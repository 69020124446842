import React from 'react';
import {Helmet} from "react-helmet"
import DataAboutus from './DataAboutus.js'
import SelectShow from './SelectShow.js'
import Pseudo from './../assets/pseudo.jpg'
import styled from 'styled-components';
import nachtzicht from "./../assets/nachtzicht.png";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";



const Explanations = ({ showItems, clickHandlerShowItem }) => {
    return (
        <StyledExplanations>
            <Helmet>
                <title>Over Lahoux Birding</title>
                <meta name="description" content="Over Lahoux Birding en uitleg van warmtebeeld en nachtzicht" />
            </Helmet>

            <SelectShow showItems={showItems} clickHandlerShowItem={clickHandlerShowItem}  />
            
            {showItems === "thermal" ?
                <React.Fragment>
                    <br />
                    <p>Met warmtebeeld blijven zelfs vogels die dicht tegen de stam zitten niet verborgen!</p>
                    <p>Vogels hebben op zich een goede isolatie. Desondanks zijn ze goed te lokaliseren met een warmtebeeld camera.
                    Vooral kleine vogels, met het formaat van vinken en roodborstjes, vallen enorm op in het bos.
            Ook de grotere roofvogels en uilen zijn meestal goed op takken te vinden.</p>
                    <p>Door het dikke verenpakket zijn watervogels zeer goed geïsoleerd en is het moeilijker om ze te vinden.
        Vrijwel altijd is de hals en de kop goed te zien met een warmtebeeld en blijven ook die niet verborgen.</p>

                    <p>Een belangrijk punt bij het gebruik van warmtebeeld zijn de weersomstandigheden.
                    Als er weinig vocht in de lucht is zijn de prestaties beter dan tijdens een regenbui.
                    De gebruiksafstand wordt daardoor beïnvloed.
            Het grote voordeel is dan wel dat als er een warm object zich in het beeldveld bevindt deze er duidelijk uitspringt.</p>
                    <p>De speciaal voor vogelaars geselecteerde modellen hebben 4 verschillende kleurmodi om bij vrijwel alle weersomstandigheden een goed beeld te hebben.</p>

                    <div className="coloroverview">
                        <ul>
                            {DataAboutus.map(colormodi => (
                                <li key={colormodi.id}>
                                    <div>
                                        <h2>{colormodi.title}</h2>
                                        <p>{colormodi.desc}</p>
                                    </div>

                                    <div className="web-cam">
                                        <video loop={true}
                                            onMouseOver={event => event.target.play()}
                                            onMouseOut={event => event.target.pause()}
                                            muted>
                                            <source src={"/videos/" + colormodi.video} type="video/mp4"></source>

                                        </video>

                                    </div>
                                </li>




                            ))}
                        </ul>
                    </div>
                    <Link to="/producten">Ga naar warmtebeeldkijkers</Link>
                    <br />
                </React.Fragment>
                :
                <React.Fragment>
                    <br />
                    <div className="nachtzicht">
                        <div className="content">
                            <p>Ons haarscherpe nachtzicht beeld laat u genieten van vogels in de schemering en bij sterk maanlicht. </p>
                            <p>De klassieke nachtzicht, genaamd helderheid- of restlichtversterker, gebruikt het bestaande licht dat nog aanwezig is in de nacht en versterkt dat tot een beeld dat wij uitstekend kunnen bekijken.</p>
                            <p>Daarvoor kan buiten onze eigen restjes kunstmatige verlichting: maanlicht, sterrenlicht, reflecties door wolken en zelfs infraroodstraling gebruikt worden.</p>
                            <p>Door dat laatste is het ook mogelijk om bij volledige duisternis met behulp van een Infraroodstraler toch beeld te kunnen krijgen.</p>
                            <p>Het hart van deze technologie is een kleine, analoge, restlichtversterkende buis die de weinige lichtdeeltjes via ultragevoelig materiaal omzet in elektronen die onder hoge spanning verveelvoudigt worden en via een, door fosfor oplichtend scherm, voor ons oog zichtbaar gemaakt worden.</p>
                            <p>Voor het oplichtende fosfor wordt er gebruikt gemaakt van het bekende groen en een zwart/wit uitvoering, genaamd Onyx. </p>
                            <p>Aangezien het om een zeer gevoelig systeem gaat is het van belang om een kijker uitsluitend te gebruiken in het donker en de buis (overdag) niet bloot te stellen aan veel licht.</p>
                            <p>Lahoux Optics werkt al decennia samen met de Nederlands/ Franse buizenfabrikant Photonis en gebruikt uitsluitend nieuwe buizen van deze marktleider zodat wij u uitsluitend hoogwaardige producten aanbieden.</p>
                        </div>
                        <div className="preview">
                            <img src={nachtzicht} alt="Hert met nachtzicht kijker, groen beeld." />
                        </div>
                    </div>
                    <Link to="/producten">Ga naar nachtzichtkijkers</Link>
                </React.Fragment>
            }
        </StyledExplanations>
    )
}
const StyledExplanations = styled.div`
    margin: 120px auto 0 auto;
    max-width: 90vw;
    font-size: 20px;
    @media (min-width: 768px) {
        max-width: 50vw;
      }

      @media (min-width: 1024px) {
        max-width: 50vw;
      }
    

p{
    max-width: 100%;
    margin-bottom: 20px;
    color: rgba(112, 112, 112, 1);
}
p:last-of-type{
    margin-bottom: 100px;
}
.coloroverview ul li{
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
    align-items: center;
    @media (min-width: 768px) {
        flex-wrap: nowrap;
      }

      @media (min-width: 1024px) {
        flex-wrap: nowrap;
      }
}
.coloroverview li div:first-of-type{
    width: 400px;
    margin-right: 60px;
}
.coloroverview div h2{
    border-bottom: 1px solid #FC751B;
    color: #170E0E;
    display: block;
    font-size: 22px;
    padding-bottom: 6px;
    margin-bottom: 10px;
    font-weight: bold;
}

.coloroverview{
    border-left: 1px solid #FC751B;
    padding-left: 20px;
    margin-left: 50px;
}
a{
    color: #170E0E;
    text-decoration: none;
    border: 1px solid #FC751B;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 50px;
    display: inline-block;
}
.web-cam { 
    width: 250px;
    height: 250px;
    border-radius: 250px;
    -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
    position: relative;
  }
  

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    
    
  }
  .nachtzicht {
      display: flex;
      justify-content: space-between;

  }
  .preview img{
      border-radius: 50%;
      width: 350px;
  }
 
`
export default Explanations