import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const CookieBanner = ({ clickHandlerCookieBanner }) => {

  return (
    <StyledCookieBanner>
      <div className="cookie-container">
        <div className="cookie-copy">
          {/* <h1>{t("cookie_h1")}</h1> */}
          <p>Lahoux Birding maakt op deze website gebruik van functionele en geanonimiseerde analytische cookies. Wij plaatsen momenteel geen cookies van websites van derden en verzamelen geen persoonsgegevens van bezoekers. Meer informatie leest u in onze <Link to="/cookieverklaring"><strong>Cookieverklaring</strong></Link>.</p>
        </div>
        <div className="cookie-buttons">
          <button
            onClick={() => {
              clickHandlerCookieBanner();
            }}
          >
            {" "}
            Sluiten
          </button>
        </div>
      </div>
    </StyledCookieBanner>
  );
};

const StyledCookieBanner = styled.div.attrs({
  className: "cookie-banner",
})`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  padding: 10px 20px;
  font-size: 14px;
  z-index: 99;
  box-shadow: rgba(0, 0, 0, 0.2) 0px -6px 20px;
  /* animation: fadeIn 1.2s ease-in-out; */

  .cookie-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .cookie-copy {
    max-width: 80%;
  }
  .cookie-copy h1 {
    margin-bottom: 20px;
    font-weight: bold;
  }
  .cookie-copy p {
    line-height: 1.3;
  }
  .cookie-copy a {
    color: #000;
  }
  .cookie-copy a:hover {
    color: #FC751B;
  }
  button {
    border: none;
    cursor: pointer;
    background-color: #707070;
    color: #fff;
    padding: 16px;
    margin-left: 14px;
    font-size: 1rem;
    border-radius: 8px;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
export default CookieBanner;
