import React from 'react';
import styled from 'styled-components';
import icoon from "./../assets/icoonwit.svg"


const Contact = () => {
        
  return (
    <Styledoverview>
        <div>
            <img src={icoon} alt="logo Lahoux Birding" />
            <div>
                <h1>Lahoux</h1>
                
                <address>
                        <span>
                            <p>Adres:</p>
                            <p>Stephensonstraat 47, 2014 KC Haarlem</p>
                        </span>
                        <p className="line">U kunt alleen op uitnodiging bij ons kantoor langskomen. Wij hebben geen winkel of showroom.</p>
                        <span>
                            <p>Tel: </p>
                            <p><a href="tel:0235514678">023 5514678</a></p>
                        </span>
                        <span>
                            <p>Mail:</p>
                            <p> <a href="mailto:info@lahouxbirding.com">info@lahouxbirding.com</a></p>
                        </span>    
                </address>
           
            </div>
        </div>    



       
    </Styledoverview>
  )
}

const Styledoverview = styled.div`
div{
  display: flex;
  justify-content: center;
  margin-top: 50px;
  align-items: center;
  flex-wrap: wrap;
  width: 100vw;
  @media (min-width: 768px) {
    flex-wrap: wrap;
    margin-top: 50px;
    
  }

  @media (min-width: 830px) {
    flex-wrap: nowrap;
    margin-top: 85px;
  }
}
div img{
  width:80%;

  @media (min-width: 768px) {
    width:50%;
    
  }

  @media (min-width: 1024px) {
    width:500px;
  }
}

div div{
max-width: 500px;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
  align-items: stretch;
  @media (min-width: 768px) {
    margin-left: 10px;
    margin-right: 10px;
  }

  @media (min-width: 1024px) {
    margin-left: 40px;
    margin-right: 0px;
  }
}
div h1{
border-bottom: 4px solid #FC751B;
padding-bottom: 6px;
margin-bottom: 10px;
display: inline;
color: white;
font-size: 32px;
font-weight: bold;
}
p, a{
color: white;
font-size: 22.4px;
margin-bottom: 40px;
line-height: 26px;
}

address{
    padding-bottom: 100px;
    font-size: 20px;
    line-height: 24px;
    
}
address span{
    display: flex;
}
address span:first-of-type{
    margin-bottom: 20px;
}
address span p:first-of-type{
    margin-right: 20px;
    width: 50px;
}
address .line{
    
    margin-bottom: 20px;
}

`

export default Contact