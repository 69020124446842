import React from 'react';
import icoon from "./../assets/icoonwit.svg"
import styled from 'styled-components';


const StyledAbout = styled.div`
div{
    display: flex;
    justify-content: center;
    margin-top: 50px;
    align-items: center;
    flex-wrap: wrap;
    @media (min-width: 768px) {
      flex-wrap: wrap;
      margin-top: 50px;
      
    }

    @media (min-width: 1024px) {
      flex-wrap: nowrap;
      margin-top: 85px;
    }
}
div img{
    width:500px;
}

div div{
  max-width: 500px;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
    align-items: stretch;
    @media (min-width: 768px) {
      margin-left: 10px;
      margin-right: 10px;
    }

    @media (min-width: 1024px) {
      margin-left: 40px;
      margin-right: 0px;
    }
}
div h1{
  border-bottom: 4px solid #FC751B;
  padding-bottom: 6px;
  margin-bottom: 10px;
  display: inline;
  color: white;
  font-size: 32px;
  font-weight: bold;
}
p, a{
  color: white;
  font-size: 22.4px;
  margin-bottom: 40px;
  line-height: 26px;
}
  
`

const Aboutus = () => {
  return (
    <StyledAbout>
        <div>
            <img src={icoon} alt="logo Lahoux Birding" />
            <div>
                <h1>Lahoux</h1>
                <p> De Lahoux warmtebeeldkijkers zijn nuttige instrumenten voor vogelaars en ecologen. Zowel overdag als 's nachts geven de warmtebeeldkijkers een belangrijke meerwaarde bij het bestuderen van vogels. Door de hoge lichaamstemperatuur van vogels zijn ze goed te zien op de display van de kijkers. 
Naast vogels zijn ook zoogdieren en andere warmbloedige dieren goed te detecteren met de Lahoux warmtebeeldkijkers. 
                </p>
            </div>
        </div>        
      
    </StyledAbout>
  )
}

export default Aboutus