import React from 'react';
import icoon from "./../assets/icoonwit.svg"
import styled from 'styled-components';


const StyledEvents = styled.div`
  div{
      display: flex;
      justify-content: center;
      margin-top: 85px;
      align-items: center;

  }
  div img{
      width:600px;
      margin-top: 50px;
  }

    
`

const Bird = () => {
  return (
    <StyledEvents>
        <div>
            <img src={icoon} alt="logo Lahoux Birding" />
      
        </div>        
      
    </StyledEvents>
  )
}

export default Bird