import React from 'react';
import logo from "./../assets/logo-lahoux.png";
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faFacebookF} from '@fortawesome/free-brands-svg-icons'
import { Link } from "react-router-dom";

const Footer = () => {
return (
    <StyledFooter>
        <div className="sitemap-wrapper">
            <nav className="sitemap">
                <ul>
                    <li><Link to="/evenementen">Evenementen</Link></li>
                    <li><Link to="/producten">Producten</Link></li>
                    <li><Link to="/woordenlijst">Woordenlijst</Link></li>
                    <li><Link to="/over-ons">Over ons</Link></li>
                    <li><Link to="/service">Service</Link></li>
                </ul>
            </nav>
            <div className="logo">
                <Link to="/">
                    <img src={logo} alt="Logo Lahoux Birding" />
                </Link>
                <div className="social-wrapper">
                    <p>Volg ons op:</p>
                    <ul className="social-icons">
                        <a href="https://www.facebook.com/LahouxBirding" target="blank" rel="noopener noreferrer"><li><FontAwesomeIcon icon={faFacebookF} /></li></a>
                        <a href="https://www.instagram.com/lahoux_birding/" target="blank" rel="noopener noreferrer"><li><FontAwesomeIcon icon={faInstagram} /></li></a>
                        <a href="https://twitter.com/LahouxB" target="blank" rel="noopener noreferrer"><li><FontAwesomeIcon icon={faTwitter} /></li></a>
                        <a href="https://www.youtube.com/channel/UCBbl6e3i9oDMzz49IuQ25OA" target="blank" rel="noopener noreferrer"><li><FontAwesomeIcon icon={faYoutube} /></li></a>
                    </ul>
                </div>
            </div>
        </div>
        <div className="fine-print">
        <ul>
        <li>
            <Link to="/cookieverklaring">Cookieverklaring</Link>
        </li>
        </ul>

        <p className="footerRights">Alle prijzen zijn adviesverkoopprijzen incl. BTW.</p>
        <p className="footerRights">
          © {new Date().getFullYear()} Lahoux Optics || Alle rechten voorbehouden
        </p>
      </div>
    </StyledFooter>
)
}

const StyledFooter = styled.footer`
    width: 100%;
    font-size: 20px;
    background-color: #393939;
    color: #fff;
    
    p{
        text-align: center;
        padding-bottom: 40px;
    }
    .logo {
        padding: 15px 0;
        }
    .logo img{
        width: 250px;
        @media (min-width: 375px) {
            width: 300px;
        }
    }
    .sitemap-wrapper{
        display: flex;
        flex-wrap: wrap;
        width: 90%;
        margin: 0 auto;
        justify-content: space-between;
        padding-top: 40px;
        @media (min-width: 768px) {
            flex-wrap: wrap;
          }
      
          @media (min-width: 1024px) {
            flex-wrap: nowrap;
          }
    }
    .sitemap li{
        padding: 1em;
    }
    .sitemap li a{
        text-decoration: none;
        color: white;
    }
    .social-icons{
        display:flex;
        list-style-type: none;
        font-size: 1.2em;

    }
    .social-icons li{
        padding: 7px;
        border: 1px solid #fff;
        border-radius: 100%;
        margin-right: 10px;
        color: #fff;
        
    }
    .sitemap li a,
    .fine-print li a {
        text-decoration: none;
        color: #fff;
    }
    .social-icons li svg{
        width: 1.2em;
        height: 1.2em;
    }
    .social-wrapper p{
        text-align: left;
        padding: 1em 0;
    }
    .social-wrapper{
        margin-bottom: 20px;

        @media (min-width: 768px) {
            flex-wrap: wrap;
          }
      
          @media (min-width: 1024px) {
            flex-wrap: no-wrap;
          }
    }
    .fine-print {
    font-size: 0.9375rem;
    border-top: 1px solid #808080;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 90%;
    margin: 20px auto 0 auto;
  }

  .fine-print ul {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
  }

  .fine-print li {
    padding-left: 20px;
  }

  .footerRights {
    font-size: 0.9375rem;
    padding-bottom: 8px;
    line-height: 1.2;
  }
`

export default Footer