import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";

const CookiePolicy = () => {

  return (
    <StyledCookiePolicy>
      <Helmet>
        <title>Cookieverklaring Lahoux Birding</title>
      </Helmet>
      
      <div className="cookie-policy">
        <h1>Cookieverklaring</h1>

        <p>Lahoux Birding maakt op deze website gebruik van functionele en analytische cookies en van local storage.</p>
        <p>Een cookie is een klein tekstbestand dat bij het eerste bezoek aan deze website wordt opgeslagen in de browser van uw computer, tablet of smartphone. Lahoux Birding gebruikt cookies met een puur technische functionaliteit. Deze zorgen ervoor dat de website naar behoren werkt en dat bijvoorbeeld uw voorkeursinstellingen onthouden worden. Deze cookies worden ook gebruikt om de website goed te laten werken en deze te kunnen optimaliseren.</p>
        <p>Daarnaast plaatsen we analytische cookies. Analytische cookies verzamelen (vaak anoniem) gegevens en statistieken om te begrijpen hoe bezoekers de website gebruiken, zodat we de website en onze dienstverlening kunnen verbeteren.</p>

        <h2>In- en uitschakelen van cookies en verwijdering daarvan</h2>
        <p>U kunt zich afmelden voor cookies door uw internetbrowser zo in te stellen dat deze geen cookies meer opslaat. Daarnaast kunt u ook alle informatie die eerder is opgeslagen via de instellingen van uw browser verwijderen.</p>
        <p>Meer informatie omtrent het in- en uitschakelen en het verwijderen van cookies kunt u vinden in de instructies van uw browser en/of met behulp van de Help-functie van uw browser.</p>

        <h2>Google Analytics</h2>
        <p>Via onze website wordt een cookie geplaatst van het Amerikaanse bedrijf Google, als deel van de “Analytics”-dienst. Wij gebruiken deze dienst om bij te houden en rapportages te krijgen over hoe bezoekers de website gebruiken. Wij gebruiken deze informatie om beslissingen te nemen over de inrichting van onze website. Tevens optimaliseren wij hiermee de werking van de website. Om zorgvuldige verwerking mogelijk te maken hebben we de volgende maatregelen getroffen:</p>

        <ul>
          <li>We hebben een bewerkersovereenkomst met Google gesloten;</li>
          <li>De informatie die Google verzamelt wordt zo veel mogelijk geanonimiseerd. Uw IP-adres wordt nadrukkelijk niet meegegeven;</li>
          <li>We hebben alle mogelijkheden die Google aanbiedt om ‘gegevens met Google te delen’ uitgezet;</li>
          <li>We maken geen gebruik van andere Google-diensten in combinatie met de Google Analytics-cookies.</li>
        </ul>

        <p>Google kan deze informatie aan derden verschaffen indien Google hiertoe wettelijk wordt verplicht, of voor zover derden de informatie namens Google verwerken. Wij hebben hier geen invloed op. Wij hebben Google niet toegestaan de verkregen analytics informatie te gebruiken voor andere Google-diensten.</p>
        <p>De informatie die Google verzamelt wordt zo veel mogelijk geanonimiseerd. Uw IP-adres wordt nadrukkelijk niet meegegeven.</p>

        <h2>Local Storage</h2>
        <p>Local storage is een vorm van lokale gegevensopslag, en is bedoeld om de website goed te laten werken. Wij gebruiken Local storage alleen om de door u gekozen taal van de website in uw browser te onthouden. Wij gebruiken local storage ook om te onthouden dat u de cookiebanner heeft gesloten, zodat deze bij uw volgende bezoek niet meer wordt weergegeven.</p>
      </div>
    </StyledCookiePolicy>
  );
};

const StyledCookiePolicy = styled.div`
  margin: 60px auto 120px auto;
  max-width: 90vw;
  font-size: 1rem;
  @media (min-width: 768px) {
    max-width: 70vw;
  }

  @media (min-width: 1024px) {
    max-width: 50vw;
  }

  .cookie-policy {
    max-width: 100vw;
    margin: 0 auto;
    @media (min-width: 768px) {
      max-width: 70vw;
    }

    @media (min-width: 1024px) {
      max-width: 50vw;
    }
  }

  .cookie-policy ul {
    list-style: disc;
    margin-left: 16px;
    margin-bottom: 20px;
    line-height: 18px;
  }

  .cookie-policy li {
    padding-bottom: 8px;
  }

  h1 {
    font-size: 1.5rem;
    font-weight: 700;
    border-bottom: 2px solid #FC751B;
    padding-bottom: 6px;
    margin-bottom: 20px;
    display: inline-block;
  }

  h2 {
    font-weight: 700;
    border-bottom: 2px solid #FC751B;
    padding-bottom: 6px;
    margin-top: 20px;
    margin-bottom: 10px;
    display: inline-block;
  }

  h3 {
    font-size: 1.25rem;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 6px;
  }

  p {
    line-height: 1.4;
    margin-bottom: 20px;
  }

  .disclaimer h2 {
    margin-bottom: 20px;
  }
`;

export default CookiePolicy;
