import React from 'react';
import icoon from "./../assets/icoonwit.svg"
import styled from 'styled-components';


const StyledHeroCookiePolicy = styled.div`
div{
    display: flex;
    justify-content: center;
    margin-top: 50px;
    align-items: center;
    flex-wrap: wrap;
    @media (min-width: 768px) {
      flex-wrap: wrap;
      margin-top: 50px;
      
    }

    @media (min-width: 1024px) {
      flex-wrap: nowrap;
      margin-top: 85px;
    }
}
div img{
    width:500px;
}

div div{
  max-width: 500px;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
    align-items: stretch;
    @media (min-width: 768px) {
      margin-left: 10px;
      margin-right: 10px;
    }

    @media (min-width: 1024px) {
      margin-left: 40px;
      margin-right: 0px;
    }
}
div h1{
  border-bottom: 4px solid #FC751B;
  padding-bottom: 6px;
  margin-bottom: 10px;
  display: inline;
  color: #fff;
  font-size: 32px;
  font-weight: bold;
}
p, a{
  color: #fff;
  font-size: 22.4px;
  margin-bottom: 40px;
  line-height: 26px;
}
  
`

const HeroCookiePolicy = () => {
  return (
    <StyledHeroCookiePolicy>
        <div>
            <img src={icoon} alt="logo Lahoux Birding" />
            <div>
                <h1>Cookieverklaring</h1>
                <p>Lahoux Birding maakt op deze website gebruik van functionele en analytische cookies en van local storage.
                </p>
            </div>
        </div>        
      
    </StyledHeroCookiePolicy>
  )
}

export default HeroCookiePolicy