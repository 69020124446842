const DataAboutus = [
	{
		id: 2,
		title: "White hot",
		desc: "De meest algemeen gebruikte setting. Alles wat warm is wordt in wit of grijstinten weergegeven. Wit valt voor detecteren goed op.",
        video: "whitehot.mp4"      
	},
	{
		id: 3,
		title: "Black hot",
		desc: "Het negatief van white hot. Alles wat warm is wordt in donkere of zwarte tinten weergegeven. Het beeld lijkt op dat van een zwart-wit monitor en werkt vaak goed onder vriezende omstandigheden. Afhankelijk van de afstand en sommige omstandigheden zijn individuele kenmerken minder goed te zien.",
        video: "blackhot.mp4"      
	},
	{
		id: 1,
		title: "Red hot",
		desc: "Vergelijkbaar met white hot. Alles wat warm is heeft grijze of witte tonen. De delen die, in vergelijking met de witte delen, nog warmer zijn worden rood afgebeeld. Vooral onder moeilijke omstandigheden met veel struik en riet valt een dier of vogel goed op. De vogelkop is daardoor juist goed zichtbaar.",
        video: "redhot.mp4"      
	},
    {
		id: 4,
		title: "Pseudo colour",
		desc: "Hiermee zijn de verschillende tinten in een object, dier of vogel goed te onderscheiden. Zowel bij individuele dieren in een vlucht of een zwerm als de gehele toom eenden zijn ermee duidelijk te herkennen.",
        video: "pseudo.mp4"      
	}
];
export default DataAboutus;