const DataGlossary = [
	{
		id: 1,
		type: "thermal",
		title: "Absolute temperatuurschaal",
		desc: "Thermodynamische temperatuurschaal, genoemd naar Lord Kelvin, waarin temperaturen worden weergegeven in Kelvins (K). Het absolute nulpunt (nul Kelvin) komt overeen met -273,15°C of -459,7°F. De grootte van de Kelvin-eenheid is dezelfde als die van de Celsius-graad."
	},
	{
		id: 2,
		type: "thermal",
		title: "Omgevingstemperatuur",
		desc: "De heersende temperatuur in de onmiddellijke omgeving van het voorwerp; de temperatuur van zijn omgeving."
	},
    {
		id: 3,
		type: "thermal",
		title: "Opening",
		desc: "Een opening of gat waar straling doorheen moet."
	},
    {
		id: 4,
		type: "thermal",
		title: "Gebied van belang (AOI)",
		desc: "Ook bekend als interessegebied. Het gebied van een beeld waar een bepaalde berekening plaatsvindt, zoals de berekening van de gemiddelde, maximale of minimale temperatuur."
	},
    {
		id: 5,
		type: "thermal",
		title: "Array",
		desc: "Veelvoud van lichtgevoelige of infraroodgevoelige elementen in camera's, detectoren of scanapparaten."   
	},
    {
		id: 6,
		type: "thermal",
		title: "Blob",
		desc: "Een groep aangrenzende pixels in een afbeelding die dezelfde waarde vertegenwoordigen, zoals alle zwart in een binaire afbeelding." 
	},
    {
		id: 7,
		type: "thermal",
		title: "Bolometer",
		desc: "Een zeer gevoelig thermometrisch instrument voor het opsporen en meten van stralingsenergie. Het belangrijkste onderdeel is een korte smalle strook, bedekt met een zwarte absorberende coating en gemonteerd aan het onderste uiteinde van een lange cilindrische buis met een stop om ongewenste straling te elimineren. De elektrische weerstand van de strip verandert met de temperatuursveranderingen die ontstaan door de absorptie van verschillende hoeveelheden stralingsenergie."    
	},
    {
		id: 8,
		type: "thermal",
		title: "Charged Coupled Device (CCD)",
		desc: "Een zelfscannende halfgeleiderbeeldvormer die gebruik maakt van MOS-technologie (Metal Oxide Semiconductor), oppervlakteopslag en informatieoverdracht. Het bestaat in het algemeen uit een condensator van een metaal-isolator-halfgeleider (MIS), waarbij meerderheidsdragers worden aangetrokken tot de halfgeleider-isolatie-interface wanneer een negatieve spanning op het metaal wordt gezet. Omkering van de spanningspolariteit creëert een gebied waar de meerderheidsdragers niet meer aanwezig zijn, een lege potentiaalput. De lading van minderheidsdragers die informatie vertegenwoordigen, hoopt zich op in de put en vult deze gedeeltelijk. Informatie wordt overgedragen van de ene put naar de andere."  
	},
    {
		id: 9,
		type: "thermal",
		title: "Charge Injection Device (CID)",
		desc: "Een beeldvormingsapparaat in vaste staat dat gebruik maakt van een beeldsensor die bestaat uit een tweedimensionale reeks gekoppelde MOS (metaaloxide halfgeleider) ladingsopslagcondensatoren en is ontworpen om nabij-infrarode energie om te zetten in elektrische signalen, waardoor een brede grijstint of tonale weergave wordt verkregen."   
	},
    {
		id: 10,
		type: "thermal",
		title: "Gekoelde infrarooddetector",
		desc: "Een infrarooddetector die een bepaalde gevoeligheid bereikt door toepassing van bepaalde cryogene temperaturen."   
	},
    {
		id: 11,
		type: "thermal",
		title: "DRI - Detectie, Herkenning, Identificatie",
		desc: "Een maatstaf voor de prestaties van warmtebeeldsystemen. - Detectie: De perceptie van een objectbeeld als zijnde aanwezig op een bepaalde locatie en onderscheiden van de omgeving. - Herkenning: De vaststelling dat een object tot een bepaalde functionele categorie behoort (bv. dier, mens, vrachtwagen, tank, enz.). - Identificatie: Het meest gedetailleerde niveau van beschrijving van een bepaald object binnen een functionele categorie (ongewapende mannelijke burger, vierdeurs vrachtwagen). Op basis van de criteriatabel van Johnson zijn 3 pixels nodig om een object te detecteren, 6 om het te herkennen en 12 om het te identificeren. Deze benadering geeft een waarschijnlijkheid van 50% om een taak van objectdetectie/herkenning/identificatie tot een goed einde te brengen en komt overeen met de maximale DRI-waarden. Om deze waarschijnlijkheid te verhogen tot 90%, moet het aantal pixels met een factor 1,8 worden verhoogd, meer bepaald tot 5,4 pixels voor detectie, 10,8 voor herkenning en 21,6 voor identificatie. De in de specificaties van het apparaat vermelde DRI-waarden worden over het algemeen alleen ter referentie gegeven en zijn mogelijk niet in overeenstemming met de in de praktijk verkregen resultaten, aangezien in sommige gevallen veel meer pixels duidelijk zichtbaar moeten zijn om een voorwerp met succes te detecteren/herkennen/identificeren. Dit zal op zijn beurt een aanzienlijke invloed hebben op de afstanden waarop een voorwerp wordt gedetecteerd/herkend/geïdentificeerd."   
	},
    {
		id: 12,
		type: "thermal",
		title: "F-getal of F-stop",
		desc: "De verhouding tussen brandpuntsafstand en lensopening (de optische diameter van de lens die inkomende energie op de FPA laat vallen). Hoe kleiner het f-getal - hoe groter de lensdiameter, wat een helderder beeld betekent met een kleinere scherptediepte."   
	},
    {
		id: 13,
		type: "thermal",
		title: "Gezichtsveld (FOV)",
		desc: "Het 2D-gebied dat kan worden gezien door het optische beeldvormingssysteem."
	},
    {
		id: 14,
		type: "thermal",
		title: "Brandpunt",
		desc: "Een vlak (door het brandpunt) loodrecht op de hoofdas van een lens of spiegel - het oppervlak waarop het beste beeld wordt gevormd.",
	},
    {
		id: 15,
		type: "thermal",
		title: "Focal Plane Array (FPA)",
		desc: "Een lineaire of tweedimensionale matrix van individuele detectorelementen, doorgaans gebruikt in het brandpunt van een beeldvormingssysteem."
	},
    {
		id: 16,
		type: "thermal",
		title: "Framerate / Frequentie",
		desc: "Ook wel Refresh Rate genoemd. Het aantal keren per seconde dat het frame van een beeld wordt gescand/ververst. Dit wordt uitgedrukt in Hertz (Hz) of frames per seconde (fps).", 
	},
    {
		id: 17,
		type: "thermal",
		title: "Versterking (thermische beeldvorming)",
		desc: "Wordt gebruikt om de prestaties van uw toestel te optimaliseren naargelang de omgevingsomstandigheden. Niet te verwarren met de versterkingsregeling voor nachtzichtsystemen, verhoogt of verlaagt Thermische versterking de gevoeligheid van de FPA om de contrasterende temperaturen van de omgeving beter weer te geven. Bijvoorbeeld, omgevingen waar alle objecten een vergelijkbare temperatuur hebben, vereisen een verhoogde gevoeligheid (gain) van de FPA om de beste beeldkwaliteit te produceren."   
	},
    
    {
		id: 18,
		type: "thermal",
		title: "Beeld subtractie",
		desc: "Een methode om twee foto's van hetzelfde onderwerp, genomen op verschillende tijdstippen, met elkaar te vergelijken."
	},
    
    {
		id: 19,
		type: "thermal",
		title: "Infrarode fotogeleider",
		desc: "Een fotogeleider die een verhoogde geleidbaarheid vertoont bij blootstelling aan infrarode straling."   
	},
    
    {
		id: 20,
		type: "thermal",
		title: "Microbolometer",
		desc: "Bij een camera ontvangen de afzonderlijke pixels verschillende hoeveelheden warmtestraling van de scène en warmen zij in verschillende mate op ten opzichte van het substraat van de bolometer-array. Het deel van elke bolometer dat opwarmt is thermisch geïsoleerd van het substraat, zodat een zeer kleine hoeveelheid ingevoerd IR-vermogen resulteert in een meetbare verandering van de temperatuur van de bolometer. De mate van verwarming van elke pixel (en dus de intensiteit van het IR-beeld) wordt bepaald door een bekende stroom of een spanning door een weerstandselement in het thermisch geïsoleerde deel van de bolometer te sturen."   
	},
    
    {
		id: 21,
		type: "thermal",
		title: "Correctie voor niet-uniformiteit (NUC)",
		desc: "Een van de minder gewenste eigenschappen van moderne FPA-detectoren is hun relatieve niet-uniformiteit van detector tot detector. Dit is het gevolg van variaties in het fabricageproces en het detectormateriaal zelf. Het blijft een feit dat alle FPA-detectoren bij de bouw tamelijk niet-uniform reageren op temperatuur. Om hiervoor te corrigeren hebben vrijwel alle FPA-camera's een soort correctie voor niet-uniformiteit in de camera ingebouwd. De methoden om dit probleem te corrigeren verschillen sterk van fabrikant tot fabrikant. De eenvoudigste aanpak is wanneer een lensdop op de camera wordt geplaatst en een 'NUC'-knop wordt ingedrukt, waarna de camera de uniformiteit corrigeert op basis van de temperatuur van de lensdop. Andere systemen hebben een 'paddle' met uniforme temperatuur in de camera die periodiek in het optische pad wordt geplaatst om de detector te corrigeren. Sommige systemen hebben een permanente multi-point niet-uniformiteitscorrectie, waarbij de detector wordt gecorrigeerd bij verschillende scènetemperaturen voor elk bereik en de gegevens vervolgens in het apparaat worden opgeslagen, zodat de gebruiker nooit een niet-uniformiteitscorrectie in het veld hoeft uit te voeren. Dit lijkt de beste aanpak, omdat de gebruiker niet hoeft in te grijpen en de niet-uniformiteitscorrectie bij verschillende temperaturen plaatsvindt en niet alleen bij de temperatuur van de lensdop, zoals bij andere benaderingen. Correctie voor niet-uniformiteit is een belangrijke parameter waarmee de P/PM-gebruiker rekening moet houden, aangezien dit moet gebeuren telkens wanneer u van bereik of lens verandert of wanneer de bedrijfstemperatuur van de camera varieert. Systemen die dit automatisch doen, blijken het gemakkelijkst te gebruiken in het veld. De beste correctie voor niet-uniformiteit vindt plaats bij een temperatuur die zo dicht mogelijk bij de objecttemperatuur ligt." 
	},
    
    {
		id: 22,
		type: "thermal",
		title: "Optische en digitale zoom",
		desc: "Optische vergroting houdt rechtstreeks verband met de brandpuntsafstand van de lens; de lens beweegt naar binnen of naar buiten om een voorwerp beter te kunnen zien, waardoor het vergroot wordt. Inzoomen met een optische zoom resulteert in een kleinere beeldhoek, maar behoudt de beeldkwaliteit. Digitale zoom daarentegen bestaat volledig uit pixels. Bij digitale zoom worden de pixels van een beeld digitaal vergroot, wat resulteert in een waziger (duidelijker 'gepixeld') beeld in vergelijking met optische zoom."    
	},
    
    {
		id: 23,
		type: "thermal",
		title: "Pixel",
		desc: "Samentrekking van 'beeldelement'. Een klein element van een scène, vaak het kleinst oplosbare gebied, waarin een gemiddelde helderheidswaarde wordt bepaald en gebruikt om dat deel van het beeld weer te geven. Pixels worden in een rechthoekige array gerangschikt om een compleet beeld te vormen."
	},
    {
		id: 24,
		type: "thermal",
		title: "Thermische gevoeligheid",
		desc: "De kwantitatieve beschrijving van hoe goed een thermisch apparaat temperatuurverschillen onderscheidt. Ze wordt uitgedrukt in mK (millikelvin of een duizendste van een Kelvin). Een lagere numerieke waarde (in mK) wijst op een hogere gevoeligheid, omdat het apparaat kleinere temperatuurverschillen kan onderscheiden. De typische waarde van de FPA-gevoeligheid is 50mK, wat betekent dat de detector voorwerpen kan onderscheiden als het verschil in hun oppervlaktetemperatuur 50mK of meer bedraagt, bv. 11,95 °C en 12 °C."
	},
    {
		id: 25,
		type: "thermal",
		title: "Thermo-elektrische koeling",
		desc: "Een koelmethode gebaseerd op het Peltier-effect. Wanneer een elektrische stroom door een thermokoppel van twee ongelijksoortige metalen loopt die op twee plaatsen met elkaar zijn verbonden, wordt warmte geabsorbeerd bij de koude kruising en afgevoerd bij de warme kruising. De koude verbinding kan in de te koelen kamer worden gemonteerd."  
	},
    {
		id: 26,
		type: "nightvision",
		title: "Auto-Gating",
		desc: "Auto-Gating is de elektronische oplossing die de frequentie van de kathodespanning vermindert door de spanning snel aan en uit te schakelen. Het is een gepatenteerd middel om de hoge MTF (Modulation Transfer Function) en resolutie te behouden die worden verkregen bij de volle bedrijfsspanning, terwijl de belasting van de beeldversterkerbuis door hoge verlichtingsniveaus wordt verminderd. Dit gebeurt door de gemiddelde stroom van de fotokathode te verminderen. Auto-Gating werkt voortdurend om de kwaliteit van het beeld te verbeteren, niet alleen tijdens de overgang van dag-nacht-dag, maar ook onder dynamische lichtomstandigheden, zoals bij militaire operaties in stedelijk terrein, die kenmerkend zijn voor veel van de huidige missies. Auto-Gating is nu standaard op de meeste Photonis beeldversterkerbuizen."  
	},
    {
		id: 27,
		type: "nightvision",
		title: "Zwarte vlekken - Smetten",
		desc: "Cosmetische oneffenheden in de beeldversterker of vuil/deeltjes tussen de lenzen. Zwarte vlekken (black spots) in de beeldversterker hebben geen invloed op de prestaties of betrouwbaarheid van het apparaat en zijn inherent aan het productieproces."   
	},
    {
		id: 28,
		type: "nightvision",
		title: "Helderheidsversterking",
		desc: "Bij een beeldversterkingsbuis is de helderheidsversterking de verhouding tussen de helderheid van het uitgangssignaal in eenheden van lm/cm2"
	},
    {
		id: 29,
		type: "nightvision",
		title: "Bescherming tegen heldere bronnen (BSP)",
		desc: "Een elektronische functie die de spanning naar de fotokathode verlaagt wanneer het nachtzichtapparaat wordt blootgesteld aan felle lichtbronnen zoals kamerverlichting of autolichten. BSP beschermt de beeldbuis tegen schade en verlengt de levensduur. BSP kan echter tot gevolg hebben dat de resolutie afneemt wanneer het functioneert." 
	},
    {
		id: 30,
		type: "nightvision",
		title: "Heldere plekken",
		desc: "Dit zijn door het signaal veroorzaakte oneffenheden in het beeldgebied, veroorzaakt door een fout in de laag op de Kathode. Een bright spot is een klein, niet-uniform, helder gebied dat kan flikkeren of constant lijken. Lichtvlekken verdwijnen gewoonlijk wanneer het licht wordt geblokkeerd. Niet alle lichtvlekken maken het ANVIS onbruikbaar. Een test kan als volgt worden uitgevoerd: Plaats een holle hand over de lens om al het licht te blokkeren. Controleer of een lichtvlek niet gewoon een helder gebied in de bekeken scène is. Als de heldere vlek blijft bestaan, is er sprake van een emissiepunt dat moet worden gecontroleerd."  
	},
    {
		id: 31,
		type: "nightvision",
		title: "Collimatie",
		desc: "De handeling waarbij lichtstralen in parallelle lijnen lopen. Ook het uitlijnen van de verschillende interne optische assen van een systeem met elkaar."  
	},
    {
		id: 32,
		type: "nightvision",
		title: "Convergentie",
		desc: "De richtingsverplaatsing van de ogen van een waarnemer naar binnen om een nabijgelegen object te bekijken, d.w.z. het kruisen van de ogen."   
	},
	{
		id: 33,
		type: "nightvision",
		title: "Kippengaas",
		desc: "Een onregelmatig patroon van donkere lijnen in het beeldgebied of in delen daarvan. In het slechtste geval vormen deze lijnen zeshoekige of vierkante lijnen."  
	},
	{
		id: 34,
		type: "nightvision",
		title: "Vervorming",
		desc: "Drie soorten vervorming zijn het belangrijkst voor nachtzichtapparatuur: geometrische, `S-vervorming` en scheurvervorming. Geometrische vervorming is inherent aan alle Gen 0 (infraroodbuizen, bijv. B-20) en Gen I beeldversterkers en aan sommige Gen II beeldversterkers die gebruik maken van elektrostatische inversie van het beeld in plaats van glasvezel-optische inversie. Geometrische vervorming wordt geëlimineerd in beeldbuizen die een microkanaalplaat en glasvezel gebruiken voor beeldinversie, maar in deze buizen kan enige S-vervorming optreden. S-vervorming - het resultaat van de verdraaiing bij de vervaardiging van glasvezeltwisters.) Meestal is de S-vervorming zeer klein en moeilijk met het blote oog waar te nemen. Sheer Distortion kan optreden in elke beeldbuis die glasvezelbundels gebruikt voor het fosforscherm. Het verschijnt als een splitsing of dislocatie in een rechte lijn in het beeld, alsof de lijn is verschoven."   
	},
	{
		id: 35,
		type: "nightvision",
		title: "Elektronische ruis",
		desc: "Ook bekend als Scintillatie. Een zwak, willekeurig, flitsend effect in het hele beeldgebied. Scintillatie is een normaal kenmerk van microkanaalplaat beeldversterkers en is meer zichtbaar bij zeer weinig licht."   
	},
	{
		id: 36,
		type: "nightvision",
		title: "Equivalente achtergrondverlichting (EBI)",
		desc: "Dit is de hoeveelheid licht die te zien is in een beeldbuis die aanstaat maar waar helemaal geen licht op de fotokathode valt; het wordt beïnvloed door de temperatuur: hoe warmer de nachtkijker, hoe helderder de achtergrondverlichting. EBI wordt gemeten in lumen per vierkante centimeter (lm/cm2), waarbij geldt: hoe lager de waarde, hoe beter. Het EBI-niveau bepaalt het laagste lichtniveau waarbij men iets kunt waarnemen. Onder dit lichtniveau worden objecten gemaskeerd door de EBI."   
	},
	{
		id: 37,
		type: "nightvision",
		title: "Glasvezelomvormer. Of twister.",
		desc: "Een bundel microscopische lichtdoorlatende glasvezels die 180 graden gedraaid zijn.",   
	},
	{
		id: 38,
		type: "nightvision",
		title: "Gezichtsveld (FOV)",
		desc: "De breedte of ruimtelijke hoek van de buitenwereld die door de versterkerbuizen kan worden bekeken, gemeten in laterale en verticale richting. Typische NVG's hebben een gezichtsveld van 40°. Er zijn NVG's in ontwikkeling die dit gezichtsveld aanzienlijk trachten te vergroten in een poging de prestaties van de piloot te verbeteren. Een voorbeeld van een NVG met groot gezichtsveld zijn de panoramische NVG."  
	},
	{
		id: 39,
		type: "nightvision",
		title: "FOM - Prestatiegetal",
		desc: "De FOM is een indicatie van de prestatie, uitgedrukt in een waarde, om de prestaties van een beeldversterkerbuis te bepalen. Het wordt als volgt berekend: SN/R x lp/mm. (SN/R = signaal/ruisverhouding (de gevoeligheid) X lp/mm = lijnparen/mm (de resolutie)). Aangezien de productie van buizen zeer moeilijk is, verschillen ze allemaal van elkaar. Dit betekent dat de FOM-waarde van buis tot buis verschilt. Daarom wordt elk nachtzichtapparaat van Lahoux Optics geleverd met een meetblad met de individuele waarden van de geassembleerde buis. De FOM-waarde varieert ruwweg tussen 1000 en 2500. Boven 1500 is de FOM zeer goed. Boven 1800 zijn de buizen normaliter voorbehouden aan overheidsgebruik. Lahoux Optics kan echter buizen tussen 1800 en 2000 FOM aan de particuliere sector leveren. Beeldversterkerbuizen met een hogere FOM dan 2000 zijn uitsluitend voor overheidstoepassingen. Aangezien de FOM een culminatie is van gevoeligheid en resolutie kan een buis met dezelfde FOM zeer verschillende prestaties leveren. De ene kan een hoge resolutie en een lagere SN/R hebben, terwijl de andere een lagere resolutie en een hogere SN/R kan hebben. Daarom is de FOM een gestandaardiseerde indicatie van de prestaties."    
	},
	{
		id: 40,
		type: "nightvision",
		title: "Vaste patroonruis (FPN)",
		desc: "Een vaag hexagonaal (honingraat) patroon in het hele beeldgebied dat meestal optreedt bij veel licht. Dit patroon is inherent aan de structuur van de microkanaalplaat en is zichtbaar in vrijwel alle Gen II- en Gen III-systemen als het lichtniveau hoog genoeg is."   
	},
	{
		id: 41,
		type: "nightvision",
		title: "Versterking",
		desc: "Ook wel helderheidsversterking of luminantie versterking genoemd. (luminance gain) Dit is het aantal keren dat een beeldversterkerbuis de lichtinput versterkt. Het wordt gewoonlijk gemeten als buisversterking en systeemversterking  (system gain). In elk nachtzichtsysteem wordt de buisversterking verminderd door de lenzen van het systeem en beïnvloed door de kwaliteit van de optiek of eventuele filters."
	},
	{
		id: 42,
		type: "nightvision",
		title: "Galliumarsenide (GaAs)",
		desc: "Het halfgeleidermateriaal dat wordt gebruikt bij de vervaardiging van de Gen III-fotokathode."
	},
	{
		id: 43,
		type: "nightvision",
		title: "Generaties beeldversterkers",
		desc: "Beeldversterkerbuizen worden geclassificeerd aan de hand van generatienummers (GEN) die door het Night Vision Laboratory van het Amerikaanse leger worden toegekend. Elk GEN duidt een nieuw contract of productieproces aan. Opmerking: Generaties definiëren niet de prestatie specificaties van de beeldversterker. Generatie I. Het begon met elektrostatisch gefocuste Generatie I buizen met een hoge beeldresolutie, een groot dynamisch bereik en weinig ruis. Generatie II. Introduceerde de Micro kanaal Plaat voor een veel hogere versterking in de jaren 80. De oorspronkelijke beeldresolutie was minder dan die van de eerste generatie, maar de versterking was veel hoger. Generatie III. Eind jaren 80 werd een beeldversterker met een GaAs fotokathode ontwikkeld met een verbeterde gevoeligheid in het nabije Infrarood. Eind jaren negentig kwamen Gen III-buizen met sterk verbeterde prestaties op de markt. Deze types worden Gen III Omni III en Gen III Omni IV genoemd. In 1996 introduceerde PHOTONIS de nieuwe Europese standaard voor beeldvorming bij weinig licht. Deze nieuwe prestatiefamilie levert superieure prestaties die de prestaties van GEN III-beeldversterkers overtreffen."  
	},
	{
		id: 44,
		type: "nightvision",
		title: "Honingraat",
		desc: "Zie vaste patroonruis."
	},
	{
		id: 45,
		type: "nightvision",
		title: "Beeldverschil, Beelddispariteit",
		desc: "Deze toestand kan zich voordoen wanneer er een verschil in helderheid is tussen de twee beeldversterkers van een binoculaire kijker." 
	},
	{
		id: 46,
		type: "nightvision",
		title: "Beeldvervorming",
		desc: "Dit probleem wordt eenvoudiger opgemerkt bij meer licht. De beeldvervorming blijkt uit verticale objecten, zoals bomen of palen, die lijken te golven of te buigen wanneer de gebruiker zijn hoofd verticaal beweegt. Of horizontaal, wanneer het grondoppervlak in de bewegingsrichting lijkt omhoog te komen of weg te zakken. De vervorming verandert niet tijdens de levensduur van een beeldversterker. Grenzen aan de toegestane vervorming zijn een belangrijk onderdeel van de prestatiespecificaties, aangezien overmatige vervorming het zicht op het beeld kan belemmeren en daarmee het vermogen van de gebruiker om de noodzakelijke vliegmanoeuvres uit te voeren."   
	},
	{
		id: 47,
		type: "nightvision",
		title: "Lijnparen per millimeter (lp/mm)",
		desc: "Deze eenheid wordt gebruikt om de resolutie van de beeldversterker te meten en wordt meestal bepaald op basis van een testdoelwit van de V.S. luchtmacht uit 1951. Het doelwit is een reeks patronen van verschillende grootte, bestaande uit drie horizontale en drie verticale lijnen. De lijnen en afstand tussen lijnen in elk van de verschillende patronen verschillen in breedte, hoe smaller de breedte, hoe groter de resolutie die nodig is om de lijnen in een bepaald patroon te onderscheiden. Tijdens een resolutietest moeten alle horizontale en verticale lijnen van een bepaald patroon duidelijk worden onderscheiden wil een beeldversterker de resolutie bereiken die door dat patroon wordt vertegenwoordigd."  
	},
	{
		id: 48,
		type: "nightvision",
		title: "Lumen",
		desc: "De eenheid die het aantal fotonen (licht) aangeeft die het menselijk oog in één seconde kan waarnemen."
	},
	{
		id: 49,
		type: "nightvision",
		title: "Lux",
		desc: "Een eenheidsmaat voor verlichting. De verlichtingssterkte die wordt geproduceerd op een oppervlak van één vierkante meter, door een uniforme puntbron met een intensiteit van één candela, of één lumen per vierkante meter."   
	},
	{
		id: 50,
		type: "nightvision",
		title: "Vergroting",
		desc: "Het vergrotingsvermogen van het objectief. Vier maal vergroting (4X) geeft aan dat het beeld vier keer groter lijkt (of dichterbij) dan wanneer het wordt bekeken met een 1X lens."
	},
	{
		id: 51,
		type: "nightvision",
		title:"Microampère per lumen",
		desc: "De maat voor de elektrische stroom (A) die door een fotokathode wordt geproduceerd wanneer deze wordt blootgesteld aan een gemeten hoeveelheid licht (lumen)."    
	},
	{
		id: 52,
		type: "nightvision",
		title: "Microkanaalplaat (MCP)",
		desc: "Een met metaal gecoate glazen schijf die, onder hoogspanning, de door de fotokathode geproduceerde elektronen vermenigvuldigt. Deze MCP's kunnen worden geproduceerd met een verschillend aantal microkanalen, van 6 tot 11 miljoen poriën. Hoe meer poriën, hoe hoger de resolutie kan zijn, waardoor de prestaties van de versterkerbuis verbeteren. Elektronen die deze kanalen binnenkomen, raken de wanden en stoten extra elektronen af, die op hun beurt weer meer elektronen afstoten, waardoor een cascade-effect ontstaat. De MCP elimineert ook vervormingseffecten van eerdere systemen." 
	},
	{
		id: 53,
		type: "nightvision",
		title: "MILSPEC",
		desc: "Term voor militaire specificatie. Dit zijn de minimaal aanvaardbare eisen voor producten die door het Amerikaanse ministerie van Defensie worden aangeschaft. Het gebruik van de term MILSPEC geeft aan dat het product voldoet aan de geldende militaire specificaties."   
	},
	{
		id: 54,
		type: "nightvision",
		title: "Nabij-infrarood",
		desc: "De kortste golflengten van het infraroodgebied, gewoonlijk 750 tot 2.500 nanometer (nm). GEN II werkt van ongeveer 440 tot 950 nanometer."   
	},
	{
		id: 55,
		type: "nightvision",
		title: "Variatie in uitvoerhelderheid",
		desc: "Deze toestand komt tot uiting in delen met verschillende helderheid in, of over het hele beeldgebied. De lagere contrasten vertonen geen duidelijke scheidslijnen en doen geen afbreuk aan de beeldkwaliteit. Deze toestand mag niet worden verward met schaduw."    
	},
	{
		id: 56,
		type: "nightvision",
		title: "Fosforscherm",
		desc: "Het fosforscherm zet elektronen om in fotonen. Een zeer dunne laag fosfor wordt aangebracht op de uitgangsoptiek en zendt licht uit wanneer deze door elektronen wordt geraakt. Zie ook Fotokathode."   
	},
	{
		id: 57,
		type: "nightvision",
		title: "Fotokathode",
		desc: "Het ingangsoppervlak van een beeldversterker wat lichtenergie absorbeert (in de vorm van fotonen) en op zijn beurt elektrische energie vrijgeeft in de vorm van een elektron. Het gebruikte materiaal is een onderscheidend kenmerk (bijvoorbeeld gevoeligheid, spectrumbreedte) van de generaties beeldversterkers."  
	},
	{
		id: 58,
		type: "nightvision",
		title: "Fotorespons (PR)",
		desc: "Zie Fotogevoeligheid."  
	},
	{
		id: 59,
		type: "nightvision",
		title: "Fotogevoeligheid (Fotorespons)",
		desc: "Ook wel gevoeligheid van de fotokathode of fotorespons genoemd. Het vermogen van het fotokathodemateriaal om bij blootstelling aan lichtgolven (fotonen) een elektrische respons te produceren. Gewoonlijk gemeten in microampère stroom per lumen (mA/lm)." 
	},
	{
		id: 60,
		type: "nightvision",
		title: "Resolutie",
		desc: "Het vermogen van een beeldversterker of nachtzichtsysteem om onderscheid te maken tussen objecten die dicht bij elkaar liggen. De beeldversterkerresolutie wordt gemeten in lijnparen per millimeter (lp/mm, zie dt onderwerp), terwijl de systeemresolutie wordt gemeten in cycli per miliradiaal. Voor een bepaald nachtzichtsysteem blijft de resolutie van de beeldversterker constant, terwijl de systeemresolutie kan worden beïnvloed door wijziging van het objectief of het oculair, door toevoeging van vergrotings- of relaislenzen. Vaak verschilt de resolutie van hetzelfde nachtzichtapparaat sterk wanneer deze in het midden van het beeld en aan de rand van het beeld wordt gemeten. Dit is vooral van belang voor toestellen die zijn geselecteerd voor foto of video, waarbij de volledige beeldresolutie belangrijk is."  
	},
	{
		id: 61,
		type: "nightvision",
		title: "Scintillatie",
		desc: "Ook bekend als elektronische ruis. Een zwak, willekeurig, sprankelend/flitsend  effect in het hele beeldgebied. Scintillatie is een normaal kenmerk van microkanaalplaatbeeldversterkers en is meer zichtbaar bij zeer weinig licht."   
	},
	{
		id: 62,
		type: "nightvision",
		title: "Scherm",
		desc: "De uitgang van de beeldbuis die het zichtbare beeld produceert. Op het binnenoppervlak van het scherm wordt fosfor (P) gebruikt om licht te produceren, waardoor het beeld ontstaat. In beeldversterkerbuizen worden verschillende fosforen gebruikt, afhankelijk van fabrikant en buisgeneratie."  
	},
	{
		id: 63,
		type: "nightvision",
		title: "Signaal-ruisverhouding (SNR)",
		desc: "Een maat voor het lichtsignaal dat het oog bereikt, gedeeld door de ruis die het oog waarneemt. (Oplossend vermogen) De SNR van een beeldbuis bepaalt de lage-lichtresolutie van de beeldbuis; hoe hoger de SNR, hoe beter de beeldbuis in staat is objecten met een goed contrast bij weinig licht op te lossen. Omdat SNR rechtstreeks verband houdt met de gevoeligheid van de fotokathode en ook rekening houdt met de fosforefficiëntie en de bedrijfsspanning van de MCP, is het de beste indicator voor de prestaties van een beeldversterker."
	},
	{
		id: 64,
		type: "nightvision",
		title: "Systeemversterking",
		desc: "Gelijk aan de buisversterking min de verliezen veroorzaakt door systeemcomponenten zoals lenzen, bundelsplitsers en filters."  
	}

    

    
];
export default DataGlossary;