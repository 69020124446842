import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import SelectShow from './SelectShow.js'
import DataProducts from './DataProducts'
import styled from 'styled-components';
import {
    BrowserRouter as Router,
    Link
} from "react-router-dom";


const Productoverview = ({ showItems, clickHandlerShowItem }) => {

    return (
        <Styledoverview>
            <Helmet>
                <title>Producten</title>
                <meta name="description" content="Productoverzicht van Lahoux Birding kijkers" />
            </Helmet>

            <SelectShow showItems={showItems} clickHandlerShowItem={clickHandlerShowItem}  />
            
            {showItems === "thermal" ?
                <React.Fragment>
                    
                    <div className="productoverview">
                        <ul>
                            {DataProducts
                                .filter(product => product.type === "thermal")
                                .map(product => (
                                    <Link to={`/producten/${product.url}`} key={product.id}>
                                        <li className="content">


                                            <img src={"/productafbeeldingen/" + product.images[0]} alt="product foto" />
                                            <div className="copy">
                                                {product.new && <p className="banner">Nieuw</p>}
                                                <span></span>
                                                <h2>{product.title}</h2>
                                                {product.subtitle ? <p>({product.subtitle})</p> : ""}
                                                <p>{product.shortDes}</p>
                                            </div>
                                            <div className="line"></div>

                                        </li>
                                    </Link>
                                ))}
                        </ul>
                    </div>
                </React.Fragment>
                :

                <React.Fragment>

                    <div className="productoverview">
                        <ul>
                            {DataProducts
                                .filter(product => product.type == "night")
                                .map(product => (

                                    <Link to={`/producten/${product.url}`} key={product.id}>
                                        <li className="content">


                                            <img src={"/productafbeeldingen/" + product.images[0]} alt="product foto" />
                                            <div className="copy">
                                                {product.new && <p className="banner">Nieuw</p>}
                                                <span></span>
                                                <h2>{product.title}</h2>
                                                {product.subtitle ? <p>({product.subtitle})</p> : ""}
                                                <p>{product.shortDes}</p>
                                            </div>
                                            <div className="line"></div>

                                        </li>
                                    </Link>

                                ))}

                        </ul>
                    </div>
                </React.Fragment>
            }
        </Styledoverview>
    )
}
const Styledoverview = styled.div`
    margin: 150px auto 0 auto;
    max-width: 90vw;
    @media (min-width: 768px) {
        max-width: 80vw;
       }
   
       /* @media (min-width: 1024px) {
        max-width: 60vw;
       }  */
    .title{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .title h1{
        text-transform: uppercase;
        font-size: 40px;
        color: #FC751B;                
    }
    .title span{
        height: 2px;
        background-color: grey;
        width: 400px;
    }
    .productoverview{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
         
    }
    .productoverview .content{
        max-width: 80vw;
        margin: 0 auto;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
        @media (min-width: 768px) {
            max-width: 60vw;
           }
       
           @media (min-width: 1024px) {
            max-width: 40vw;
           } 

           @media (min-width: 1380px) {
            max-width: 30vw;
           } 
        
    }
    .productoverview .content h2{
        font-size: 30px;
        margin-bottom: 10px;
        font-weight: bold;
        padding-top: 10px;
        
    }
    .productoverview .content p{
        color: #707070;
        margin-bottom: 40px;
        line-height: 24px;
        font-size: 20px;
    }
    .productoverview .copy{
        border-top: 2px solid #FC751B;
        
    }

    .productoverview a{
        text-decoration: none;
        color: #707070;
    }
    .productoverview a:visited, .productoverview a:hover, .productoverview a:active{
        color: #707070;
    }
    .line{
        height: 300px;
        width: 1px;
        background-color: #FC751B;
        align-self: center;
    }
    ul{
        margin-bottom: 150px;
    }
    a:last-of-type .line{
        display: none;
     
    }
    img{
        align-self: center;
        width: 100%;
        @media (min-width: 768px) {
           width: 70vw;
          }
      
          @media (min-width: 1024px) {
            width: 40vw;
          }  
    }

    .productoverview .content p.banner{
        background-color: #fc751b;
        color: #fff;
        padding: 10px 15px;
        border-radius: 8%;
        font-weight: bold;
        text-transform: uppercase;
        display: inline-block;
        margin-bottom: 8px;
        margin-top: 10px;
    }
    

`
export default Productoverview