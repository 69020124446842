import React from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player'
import schemering from "./../assets/vogelsschemering.jpg";
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";
import VideoElement from './VideoElement';





const HomeArticle = () => {
  

  return (
    <>
      <StyledHome>
          
          <article>
              <div><img src={schemering}/></div>
              <div className="article-copy">
                <h1>Scherp zicht, ook in de duisternis</h1>
                <p>Met de producten van Lahoux Birding wordt vogels kijken in het donker mogelijk. 
                  Zo kunt u de warmtebeeldkijkers gebruiken om een bosuil in het park op te sporen of te zien hoe veel vogels er ’s nachts overvliegen. 
                  Met het gedetailleerde beeld van de warmtebeeldkijkers van Lahoux Optics worden zelfs kleine overvliegende zangvogels zichtbaar! </p>
                <Link to="/producten">Ontdek onze modellen</Link>
              </div>  
              
          </article> 

          <article>
            <VideoElement video={"/productafbeeldingen/Nachtzwaluw-Lahoux-Spotter-35.mp4"} />
            
            <div className="article-copy">
              <h1>Nachtzwaluw opgenomen met de Lahoux Spotter 35</h1>
              <p>Hier ziet u een video van een langsvliegende nachtzwaluw (<span className="em">Caprimulgus europaeus</span>), opgenomen met de Lahoux Spotter 35. De Spotter 35 (nieuwe generatie) is de ideale warmtebeeldcamera om zowel van dichtbij als op grotere afstanden vogels te observeren en te ontdekken.
              </p>
              <Link to="/producten/Lahoux-Spotter-35">Meer over de Spotter 35</Link>
            </div>  
          </article>

      </StyledHome>
     
    </>
  )
}
const StyledHome = styled.div`
article{
    display: flex;
    flex-wrap: wrap;
    margin: 120px auto 120px auto;
    align-items: top;
    @media (min-width: 768px) {
      flex-wrap: wrap;
      width: 60vw;
    }

    @media (min-width: 1400px) {
      flex-wrap: nowrap;
    }
      
  }

  img, .video-element{   
    width: 100%;
    height: auto;
    display: block;

  
  }
  article div{  
    width: 100%;
  }

  .article-copy{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin: 20px 20px 0 20px;

    @media (min-width: 768px) {
      margin-top: 20px;
    }

    @media (min-width: 1400px) {
      margin-top: 0px;
      width: 70%;
    }
  }
  article div p{
    color: #393939;
    font-size: 1.2em;
    line-height: 24px;
    margin-bottom: 10px;
  }

  article h1{
    font-size: 23px;
    font-weight: bold;
    border-bottom: 2px solid #FC751B;
    padding-bottom: 6px;
    margin-bottom: 15px;
    display: inline;
    color: #170E0E;
  
  }
  article a{
      border: solid 1px #707070;
      border-radius: 5px;
      padding: 10px;
      font-size: 1.2em;
      text-decoration: none;
      color: #393939;
  }
  article a:visited, article a:active, article a:hover {
    color:#393939 ;
  }

  .em {
    font-style: italic;
  }
`;
export default HomeArticle