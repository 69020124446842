import React from 'react';
import { Helmet } from "react-helmet";
import vogelen from "./../assets/dagjevogelen.jpg";
import styled from 'styled-components';
import veldshop from "./../assets/logo-veldshop-nl.png";
import dagje from "./../assets/DIDN_logo_transparant.png";

const StyledDealers = styled.div`

    max-width: 100vw;
    margin: 120px auto 0 10px;

    @media (min-width: 768px) {
        max-width: 60vw;
        margin: 120px auto 0 auto;
      }
  
      @media (min-width: 1024px) {
        max-width: 60vw;
        margin: 120px auto 0 auto;
      }
  .card img{
      width: 80%;
      padding: 12px;
      
  }
    .card{
       height: 350px;
       width: 95vw;
       @media (min-width: 768px) {
        width: 450px;
      }
  
      @media (min-width: 1024px) {
        width: 450px;
      }
       background-color: #C1B8B8;
       border: 1px solid #C1B8B8;
       margin: 0 0 50px 0;

    }
    .card p{
        text-decoration: none;
        color: #393939;
        font-size: 20px;      
    }
    .card p a{
        color: #393939;
        font-weight: bold;
    }
.card:last-of-type{
    background-color: #C1B8B8;
}
.card:first-of-type, .card:nth-of-type(2n){
    background-color: white; 
}
.cards{
    
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 40px;
    
    
}
.card{
    display: flex;
    justify-content: center;
    align-items: center;
    
  
      @media (min-width: 1024px) {
        margin-right: 40px;
      }
}
h1 {
    font-size: 23px;
    font-weight: bold;
    color: #170E09;
    border-bottom: 2px solid #FC751B;
    display: inline-block;
    padding-bottom: 6px;
    margin-bottom: 20px;
    
}
span{
    display: block;
    margin-bottom: 40px;
}

`

const Dealers = () => {
  return (
    <StyledDealers>
        <Helmet>
            <title>Dealers</title>
            <meta name="description" content="Distributeur of dealer" />
        </Helmet>

        <span>
        <h1>Dealers</h1>
        </span>
    <div className="cards">
        <a href="https://www.dagjeindenatuur.nl/" className="card" target="_blank" rel="noopener noreferrer">       
                <img src={dagje} alt="logo dagindenatuur.nl"/>
        </a>        
        <a href="https://www.dagjevogelen.nl/" className="card" target="_blank" rel="noopener noreferrer">       
            <img src={vogelen} alt="logo dagjevogelen.nl"/>
        </a>
        <a href="https://www.veldshop.nl" className="card" target="_blank" rel="noopener noreferrer">       
            <img src={veldshop} alt="logo veldshop.nl"/>
        </a>        
    </div>
    </StyledDealers>
  )
}

export default Dealers