import React from 'react';
import icoon from "./../assets/icoonwit.svg"
import styled from 'styled-components';



const Events = () => {
  return (
    <StyledEvents>
        <div>
            <img src={icoon} alt="logo Lahoux Birding" />
            <div className="content">
                <h1>Maak kennis met Lahoux</h1>
                <p>Regelmatig bieden onze partners excursies aan waarbij u kennis kunt maken met de wereld van warmtebeeld kijkers. U kunt hier zelf ervaren waarom een warmtebeeld kijker een onmisbare tool is voor een beginnende maar zeker ook de ervaren vogelaar.
                   <br/> <br/> Ook staan wij zelf geregeld op beurzen waarbij wij u onze warmtebeeldkijkers kunnen demonstreren.
                </p>
            </div>
        </div>        
      
    </StyledEvents>
  )
}


const StyledEvents = styled.div`
div{
  display: flex;
  justify-content: center;
  margin-top: 50px;
  align-items: center;
  flex-wrap: wrap;
  @media (min-width: 768px) {
    flex-wrap: wrap;
    margin-top: 50px;
    
  }

  @media (min-width: 830px) {
    flex-wrap: nowrap;
    margin-top: 85px;
  }
}
div img{
  width:80%;

  @media (min-width: 768px) {
    width:50%;
    
  }

  @media (min-width: 1024px) {
    width:500px;
  }
}

div div{
max-width: 500px;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
  align-items: stretch;
  @media (min-width: 768px) {
    margin-left: 10px;
    margin-right: 10px;
  }

  @media (min-width: 1024px) {
    margin-left: 40px;
    margin-right: 0px;
  }
}
div h1{
border-bottom: 4px solid #FC751B;
padding-bottom: 6px;
margin-bottom: 10px;
display: inline;
color: white;
font-size: 32px;
font-weight: bold;
}
p, a{
color: white;
font-size: 22.4px;
margin-bottom: 40px;
line-height: 26px;
}
`

export default Events
    