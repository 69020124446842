import React from 'react';
import { Helmet } from "react-helmet";
import DataGlossary from './DataGlossary'
import SelectShow from './SelectShow.js'
import styled from 'styled-components';



const Glossary = ({ showItems, clickHandlerShowItem }) => {
        
  return (
      <Styledoverview>
          <Helmet>
            <title>Woordenlijst</title>
            <meta name="description" content="Definitie van verschillende vakspecifieke termen" />
          </Helmet>
          <SelectShow showItems={showItems} clickHandlerShowItem={clickHandlerShowItem}  />
          <div className="glossary">
          {showItems === "thermal" ?
                <React.Fragment>
              <div className="glossaryoverview">
                  <ul>
                      {DataGlossary
                        .filter(glos => glos.type === "thermal")
                        .map(item => (
                          <li key={item.id}>
                              <p>{item.title}</p>
                              <p>{item.desc}</p>
                          </li>


                      ))}
                  </ul>
              </div>
              </React.Fragment>
              :
              <React.Fragment>
                <div className="glossaryoverview">
                    <ul>
                        {DataGlossary
                        .filter(glos => glos.type === "nightvision")
                        .map(item => (
                            <li key={item.id}>
                                <p>{item.title}</p>
                                <p>{item.desc}</p>
                            </li>


                        ))}
                    </ul>
                </div>


              </React.Fragment>
}
          </div>
      </Styledoverview>
  )
}

const Styledoverview = styled.div`
margin: 120px auto 0 auto;
    max-width: 90vw;
    font-size: 20px;
    @media (min-width: 768px) {
        max-width: 70vw;
      }

      @media (min-width: 1024px) {
        max-width: 50vw;
      }
    
    .glossary{
        max-width: 100vw;
        margin: 0 auto;
        padding-top: 50px;
        @media (min-width: 768px) {
            max-width: 70vw;
          }
    
          @media (min-width: 1024px) {
            max-width: 50vw;
          }
    }
   
    ul li p:first-of-type{
        color: #FC751B;
        font-weight: bold;
        margin-bottom: 5px;
    }
    ul li p:last-of-type{
        color: #C1B8B8;
        margin-bottom: 30px;
    }

   

`

export default Glossary